<script>
import WidgetDataTable from '@/components/stats/widgets/WidgetDataTable'
import WidgetLineGraph from '@/components/stats/widgets/WidgetLineGraph'
import WidgetBarGraph from '@/components/stats/widgets/WidgetBarGraph.vue'
import WidgetPieGraph from '@/components/stats/widgets/WidgetPieGraph.vue'
import WidgetTrendLineGraph from '@/components/stats/widgets/WidgetTrendLineGraph.vue'
import WidgetList from '@/components/stats/widgets/WidgetList.vue'
import WidgetLiveMetric from '@/components/stats/widgets/WidgetLiveMetric.vue'
import WidgetDeviceSummary from '@/components/stats/widgets/WidgetDeviceSummary.vue'
import WidgetDrivingSummary from '@/components/stats/widgets/WidgetDrivingSummary.vue'
import WidgetReportSummary from '@/components/stats/widgets/WidgetReportSummary.vue'
import WidgetChildReport from '@/components/stats/widgets/WidgetChildReport.vue'
import WidgetFloatingBarGraph from '@/components/stats/widgets/WidgetFloatingBarGraph.vue'
import BarChartPlotly from "@/components/stats/charts/BarChartPlotly.vue";
import HistogramChartPlotly from '@/components/stats/charts/HistogramChartPlotly.vue'

/***
 * This is the Parent Vue component that the 'ReportPage' uses to create it's dynamic report page components.
 * The dynamic components inherit from (extend) this component, so functionality that needs to be present in that
 * component should be added here to keep things neat.
 *
 * Note: ANY template elements in this file will break the dynamic template creation.
 */

export default {
  name: 'ReportPageBase',
  props: {
    reportParams: Object,
    devices: Array,
    statsData: Object,
    liveData: Object,
    relatedIds: Array,
    report: Object
  },
  components: {
    WidgetDataTable,
    WidgetLineGraph,
    WidgetBarGraph,
    WidgetFloatingBarGraph,
    WidgetPieGraph,
    WidgetTrendLineGraph,
    WidgetList,
    WidgetLiveMetric,
    WidgetDeviceSummary,
    WidgetDrivingSummary,
    WidgetReportSummary,
    WidgetChildReport,
    BarChartPlotly,
    HistogramChartPlotly
  },
  data () {
    return {
      data: null,
      taskId: null,
      requiredStats: null
    }
  },
  async mounted () {
    console.log('Required Stats: ', await this.getChildStats())
    console.log('Required Device Props: ', await this.getChildDeviceProps())
    this.getStats()
    this.getLiveData()
  },
  methods: {
    async getChildStats () {
      let allStats = []
      for (let child of Object.values(this.$children)) {
        if (Object.hasOwn(child, 'getRequiredStats')) {
          try {
            let stats = await child.getRequiredStats()
            allStats = allStats.concat(stats)
          } catch (e) {
            console.error('Error getting required stats:', e)
          }
        }
      }
      allStats = allStats.filter(stat => stat !== null && stat !== undefined)
      return [...new Set(allStats)]
    },
    async getChildDeviceProps () {
      let allDeviceProps = ['device_name', 'device_id']

      for (let child of Object.values(this.$children)) {
        if (Object.hasOwn(child, 'getRequiredDeviceProps')) {
          try {
            let props = await child.getRequiredDeviceProps()
            allDeviceProps = allDeviceProps.concat(props)
          } catch (e) {
            console.error('Error getting required device props:', e)
          }
        }
      }
      allDeviceProps = allDeviceProps.filter(prop => prop !== undefined && prop !== null)
      return [...new Set(allDeviceProps)]
    },
    async getStats () {
      let requiredStats = await this.getChildStats()
      this.$emit('required_stats', requiredStats)
    },
    async getLiveData () {
      let requiredDeviceProps = await this.getChildDeviceProps()
      this.$emit('required_device_props', requiredDeviceProps)
    },
    getRelatedIdLookup (deviceData) {
      // TODO - Add support for Users here when we update to Corp Reporting.
      let names = {}
      for (let device in deviceData) {
        names[deviceData[device].device_code] = deviceData[device].device_name
      }
      return names
    },
    attachProps () {
      /***
       * Attaches the standard data values to the children. Force-setting props doesn't really work as they are
       * overwritten when Vue updates the parent component. This a bit more 'magic' that I like, but it avoids
       * a lot of boilerplate for each component.
       */
      this.$children.forEach(child => {
        child.$set(child, 'statsData', this.statsData)
        child.$set(child, 'liveData', this.liveData)
        child.$set(child, 'related_ids', this.relatedIds)
        child.$set(child, 'reportParams', this.reportParams)
        child.$set(child, 'report', this.report)
      })
    }
  },
  watch: {
    '$children' (c) {
      // For some reason watch doesn't function on this specific prop
      console.warn('Children Changed', c)
      this.$nextTick(() => {
        this.attachProps()
      })
    },
    reportParams () {
      console.log('Dynamic Report Page:', this.reportParams)
      this.getStats()
    },
    statsData () {
      console.log('Got new Report Data!')
      this.$nextTick(() => {
        this.attachProps()
      })
      this.$emit('data_ready', this.statsData, this.liveData, this.report)
    },
    relatedIds () {
      console.warn('Related IDs Changed', this.$children)
      this.$nextTick(() => {
        this.attachProps()
      })
    }
  },
  computed: {
    // This is the appropriate place to add computed values that are accessible to the report templates.
    // DEV NOTE: The Vue auto-reload doesn't work very well for these dynamically generated components. You might need
    //           to refresh the whole page for changes to take effect properly (not an issue outside of local dev mode).
    firstDate () {
      if (this.data) {
        return this.data.labels[0]
      } else {
        return ''
      }
    },
    lastDate () {
      if (this.data) {
        return this.data.labels.slice(-1)[0]
      } else {
        return ''
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/variables';

.report-page {
  display: flex;
  flex-direction: column;
  align-content: center;
}

.report-heading {
  color: $theme-color-primary-3;
}

.report-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: flex-start;
}

.report-test-class {
  background: $theme-color-primary-3
}

</style>
