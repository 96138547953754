var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.chartData
      ? _c(
          "div",
          { staticClass: "chart_holder" },
          [
            _c("plotly", {
              attrs: {
                data: _vm.chartData,
                layout: _vm.chartLayout,
                config: _vm.chartConfig
              }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }