<template>
  <div v-if="childReportData">
    <ReportComponent v-show="statsData && liveData"
                     :report="childReportData"
                     :reportParams="reportParams"
                     :relatedIds="related_ids"
                     :liveData="liveData"
                     :statsData="statsData"
                     ref="childReport"
                     @required_stats="onStats"
                     @required_device_props="onProps"
    ></ReportComponent>
  </div>
</template>

<script>
import WidgetBase from '@/components/stats/widgets/WidgetBase.vue'
import ReportComponent from '@/components/stats/ReportComponent.vue'
import * as DataProvider from '@/components/helpers/DataProvider'
import * as ErrorHelper from '@/components/helpers/ErrorHelper'

/***
 * Child Report Widget
 *
 * This widget allows reports to be nested inside each other. Reports are executed with the same reportParams and
 * statistical/device prop requirements are collated and sent in a single request by the top-level report.
 */

export default {

  name: 'WidgetChildReport',
  components: { ReportComponent },
  extends: WidgetBase,
  props: {
    report_id: Number
  },
  data () {
    return {
      // NOTE: These are auto-populated by the report parent.
      statsData: null,
      liveData: null,
      related_ids: null,
      reportParams: null,
      report: null,
      // End Note
      childReportData: null,
      childStatsistics: null,
      childProps: null,
      pendingStatQuery: null,
      pendingPropsQuery: null
    }
  },
  async created () {
    this.childReportData = await this.getReport(this.report_id)
  },
  async mounted () {
  },
  methods: {
    async getRequiredStats () {
      /***
       * There's a chance we'll have to provide the stats Async here, if the componenet's children haven't had a chance
       * to register their required stats yet.
       */
      if (this.childStatsistics) {
        return this.childStatsistics
      } else {
        console.log('Report Child: Using Async Stats requirement loading...')
        // We don't have the child stats yet, so create a promise to return them and store it.
        return new Promise((resolve) => {
          this.pendingStatQuery = (reqStats) => {
            resolve(reqStats)
          }
        })
      }
    },
    async getRequiredDeviceProps () {
      if (this.childProps) {
        return this.childProps
      } else {
        console.log('Report Child: Using Async props requirement loading...')
        return new Promise((resolve) => {
          this.pendingPropsQuery = (reqProps) => {
            resolve(reqProps)
          }
        })
      }
    },
    async getReport (id) {
      let resp = await DataProvider.getReport(id)
      if (resp.success) {
        console.log(resp)
        return resp.data
      } else {
        ErrorHelper.displayDataErrorToast(resp)
      }
    },
    onStats (reqStats) {
      console.log('Report Child: Got Required Stats!')
      this.childStatsistics = reqStats
      // Check if there is an outstanding promise to provide child stats.
      if (this.pendingStatQuery) {
        this.pendingStatQuery(this.childStatsistics)
        this.pendingStatQuery = null
      }
    },
    onProps (reqProps) {
      console.log('Report Child: Got Required Props!')
      this.childProps = reqProps
      // Check if there is an outstanding promise to provide child props.
      if (this.pendingPropsQuery) {
        this.pendingPropsQuery(reqProps)
        this.pendingPropsQuery = null
      }
    }
  },
  watch: {
    '$refs.childReport' (child) {
      console.log('CHILD REPORT!', child)
    }
  }
}
</script>

<style scoped>

</style>
