var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "driving-summary-widget" }, [
      _vm.statsData && _vm.liveData && _vm.hasDrivingData
        ? _c("div", { staticClass: "driving-summary-container" }, [
            _c(
              "div",
              {
                directives: [{ name: "b-tooltip", rawName: "v-b-tooltip" }],
                attrs: { title: "Total Distance travelled in the period." }
              },
              [
                _c("div", { staticClass: "report-green-label" }, [
                  _vm._v("Total Distance")
                ]),
                _c("div", { staticClass: "report-white-text" }, [
                  _vm._v(
                    _vm._s(
                      _vm.numberFormat(_vm.getSum("DEVICE_DAILY_TOTAL_KMS"), 0)
                    ) + " km"
                  )
                ])
              ]
            ),
            _c(
              "div",
              {
                directives: [{ name: "b-tooltip", rawName: "v-b-tooltip" }],
                attrs: {
                  title: "Average distance per day (over previous 30 days)"
                }
              },
              [
                _c("div", { staticClass: "report-green-label" }, [
                  _vm._v("Daily Average Distance")
                ]),
                _c("div", { staticClass: "report-white-text" }, [
                  _vm._v(
                    _vm._s(
                      _vm.numberFormat(_vm.getMean("DEVICE_DAILY_TOTAL_KMS"))
                    ) + " km"
                  )
                ])
              ]
            ),
            _c(
              "div",
              {
                directives: [{ name: "b-tooltip", rawName: "v-b-tooltip" }],
                attrs: { title: "Total number of running hours for the period" }
              },
              [
                _c("div", { staticClass: "report-green-label" }, [
                  _vm._v("Total Hours")
                ]),
                _c("div", { staticClass: "report-white-text" }, [
                  _vm._v(
                    _vm._s(
                      _vm.timeDurationFormat(
                        _vm.getSum("DEVICE_DAILY_TOTAL_HOURS")
                      )
                    )
                  )
                ])
              ]
            ),
            _c(
              "div",
              {
                directives: [{ name: "b-tooltip", rawName: "v-b-tooltip" }],
                attrs: {
                  title: "Average hours per day (over previous 30 days)"
                }
              },
              [
                _c("div", { staticClass: "report-green-label" }, [
                  _vm._v("Daily Average Hours")
                ]),
                _c("div", { staticClass: "report-white-text" }, [
                  _vm._v(
                    _vm._s(
                      _vm.timeDurationFormat(
                        _vm.getMean("DEVICE_DAILY_TOTAL_HOURS")
                      )
                    )
                  )
                ])
              ]
            ),
            _c(
              "div",
              {
                directives: [{ name: "b-tooltip", rawName: "v-b-tooltip" }],
                attrs: { title: "Total fuel consumed during the period." }
              },
              [
                _c("div", { staticClass: "report-green-label" }, [
                  _vm._v("Fuel Consumption")
                ]),
                _c("div", { staticClass: "report-white-text" }, [
                  _vm._v(
                    _vm._s(
                      _vm.numberFormat(
                        _vm.getSum("DEVICE_DAILY_TOTAL_FUEL") * 0.01,
                        2
                      )
                    ) + " L"
                  )
                ])
              ]
            )
          ])
        : _vm._e(),
      _vm.statsData && _vm.liveData && _vm.hasDrivingData
        ? _c("div", { staticClass: "driving-summary-container" }, [
            _c(
              "div",
              {
                directives: [{ name: "b-tooltip", rawName: "v-b-tooltip" }],
                attrs: {
                  title: "MIL Alarm Events, Overheat Alarm Events, etc."
                }
              },
              [
                _c("div", { staticClass: "report-green-label" }, [
                  _vm._v("Engine Events")
                ]),
                _c("div", { staticClass: "report-white-text" }, [
                  _vm._v(
                    _vm._s(
                      _vm.numberFormat(
                        _vm.getSum("DEVICE_DAILY_EVENTS_VEHICLE_ALARM")
                      )
                    )
                  )
                ])
              ]
            ),
            _c(
              "div",
              {
                directives: [{ name: "b-tooltip", rawName: "v-b-tooltip" }],
                attrs: { title: "Speeding Events." }
              },
              [
                _c("div", { staticClass: "report-green-label" }, [
                  _vm._v("Speeding Events")
                ]),
                _c("div", { staticClass: "report-white-text" }, [
                  _vm._v(
                    _vm._s(
                      _vm.numberFormat(
                        _vm.getSum("DEVICE_DAILY_EVENTS_SPEEDING")
                      )
                    )
                  )
                ])
              ]
            ),
            _c(
              "div",
              {
                directives: [{ name: "b-tooltip", rawName: "v-b-tooltip" }],
                attrs: {
                  title:
                    "Harsh Acceleration Alarm Events, Harsh Turning Alarm Events, High RPM, Harsh Braking, etc."
                }
              },
              [
                _c("div", { staticClass: "report-green-label" }, [
                  _vm._v("Harsh Driving Events")
                ]),
                _c("div", { staticClass: "report-white-text" }, [
                  _vm._v(
                    _vm._s(
                      _vm.numberFormat(
                        _vm.getSum("DEVICE_DAILY_EVENTS_DRIVING")
                      )
                    )
                  )
                ])
              ]
            ),
            _c(
              "div",
              {
                directives: [{ name: "b-tooltip", rawName: "v-b-tooltip" }],
                attrs: { title: "Fuel efficiency for the reported period." }
              },
              [
                _c("div", { staticClass: "report-green-label" }, [
                  _vm._v("Fuel Efficiency")
                ]),
                _c("div", { staticClass: "report-white-text" }, [
                  _vm._v(
                    _vm._s(
                      _vm.numberFormat(
                        _vm.getSum("DEVICE_DAILY_TOTAL_FUEL") /
                          _vm.getSum("DEVICE_DAILY_TOTAL_KMS"),
                        2
                      )
                    ) + " L/ 100km"
                  )
                ])
              ]
            ),
            _c(
              "div",
              {
                directives: [{ name: "b-tooltip", rawName: "v-b-tooltip" }],
                attrs: {
                  title:
                    "Date of the last Vehicle Service Event entered in the system."
                }
              },
              [
                _c("div", { staticClass: "report-green-label" }, [
                  _vm._v("Last Service")
                ]),
                _c("div", { staticClass: "report-white-text" }, [
                  _vm._v(_vm._s(_vm.getLastService()))
                ])
              ]
            ),
            _c(
              "div",
              {
                directives: [{ name: "b-tooltip", rawName: "v-b-tooltip" }],
                attrs: {
                  title:
                    "Estimated Date for the next service. Today if service is overdue."
                }
              },
              [
                _c("div", { staticClass: "report-green-label" }, [
                  _vm._v("Next Service (estimated)")
                ]),
                _c("div", { staticClass: "report-white-text" }, [
                  _vm._v(_vm._s(_vm.getNextService()))
                ])
              ]
            )
          ])
        : _vm._e(),
      _vm.statsData && _vm.liveData && !_vm.hasDrivingData
        ? _c("div", { staticClass: "driving-summary-container" }, [
            _c("div", { staticClass: "report-white-text" }, [
              _vm._v("No driving data for this period.")
            ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }