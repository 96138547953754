var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "report-summary-widget" }, [
    _vm.report && this.reportParams && this.statsData
      ? _c("div", { staticClass: "report-summary-container" }, [
          _vm._m(0),
          _c("div", { staticClass: "top-level-statistic" }, [
            _c("div", { staticClass: "report-green-label" }, [
              _vm._v(_vm._s(_vm.report["name"]))
            ])
          ]),
          _c("div", { staticClass: "top-level-statistic" }, [
            _c("div", { staticClass: "report-green-label" }, [
              _vm._v("Report Date")
            ]),
            _c("div", { staticClass: "report-white-text" }, [
              _vm._v(_vm._s(_vm.dateFormatFromString(new Date())))
            ])
          ]),
          _c("div", { staticClass: "top-level-statistic" }, [
            _c("div", { staticClass: "report-green-label" }, [_vm._v("Start")]),
            _c("div", { staticClass: "report-white-text" }, [
              _vm._v(
                _vm._s(_vm.dateFormatFromString(_vm.reportParams["start_date"]))
              )
            ])
          ]),
          _c("div", { staticClass: "top-level-statistic" }, [
            _c("div", { staticClass: "report-green-label" }, [_vm._v("End")]),
            _c("div", { staticClass: "report-white-text" }, [
              _vm._v(
                _vm._s(_vm.dateFormatFromString(_vm.reportParams["end_date"]))
              )
            ])
          ]),
          _c("div", { staticClass: "top-level-statistic" }, [
            _c("div", { staticClass: "report-green-label" }, [
              _vm._v("Aggregation")
            ]),
            _c("div", { staticClass: "report-white-text" }, [
              _vm._v(_vm._s(_vm.reportParams["time_grouping"]))
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vehicle-summary-text" }, [
      _c("img", {
        staticClass: "sidebar-logo web-only",
        attrs: {
          src: require("../../../assets/protekt-logo-slim-transparent.png")
        }
      }),
      _c("img", {
        staticClass: "sidebar-logo print-only",
        attrs: {
          src: require("../../../assets/protekt-logo-slim-BW-transparent.png")
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }