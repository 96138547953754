var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "line-graph-widget" },
    [
      _vm.statsData
        ? _c("LineGraph", {
            attrs: { "chart-data": _vm.chartData, options: _vm.options }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }