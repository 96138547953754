<template>
<div class="pie-graph-widget">
  <PieGraph v-if="statsData" :chart-data="chartData" :options="options"></PieGraph>
<!--  <div class="control-row" v-if="statsData && this.statsData.labels.length > 1">-->
<!--    <div class="pie-graph-controls">-->
<!--      <i-->
<!--      class="icon-button fa fa-step-backward"-->
<!--      v-b-tooltip-->
<!--      title="Previous Date"-->
<!--      @click="labelIdxChange(-1)"-->
<!--    ></i></div>-->
<!--    <div>{{ statsData.labels[currentLabelIdx] }}</div>-->
<!--    <div class="pie-graph-controls">        <i-->
<!--      class="icon-button fa fa-step-forward"-->
<!--      v-b-tooltip-->
<!--      title="Next Date"-->
<!--      @click="labelIdxChange(1)"-->
<!--    ></i></div>-->
<!--  </div>-->
</div>
</template>

<script>
import WidgetBase from '@/components/stats/widgets/WidgetBase'
import PieGraph from '@/components/stats/charts/PieGraph.vue'
import ReportHelper from '@/components/helpers/ReportHelper'

export default {
  name: 'WidgetPieGraph',
  components: { PieGraph },
  extends: WidgetBase,
  props: {
    title: String,
    statistics: Array,
    chartOptions: Object,
    field_suffixes: Object, // Suffix to append to the field (for tooltips)
    stuff: String
  },
  data () {
    return {
      // NOTE: These are auto-populated by the report parent.
      statsData: null,
      liveData: null,
      related_ids: null,
      reportParams: null,
      report: null,
      // End Note
      defaultOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              console.log(tooltipItem, data)
              let dataset = data.datasets[tooltipItem.datasetIndex]
              return `${data.labels[tooltipItem.index]}: ${dataset.data[tooltipItem.index].toFixed(1)} ${this.getFieldSuffix(dataset)}`
            }
          }
        }
      },
      currentLabelIdx: 0
    }
  },
  methods: {
    getFieldSuffix (dataSet) {
      console.log(this.title)
      console.log(this.stuff)
      console.log('ds', dataSet)
      console.log(this.field_suffixes)
      if (this.field_suffixes && Object.hasOwn(dataSet, 'fieldId') && Object.hasOwn(this.field_suffixes, dataSet.fieldId)) {
        return this.field_suffixes[dataSet.fieldId]
      } else {
        return ''
      }
    },
    numberFormat (value) {
      if (value) {
        return value.toFixed(2)
      } else {
        return 0
      }
    },
    labelIdxChange (val) {
      if ((this.currentLabelIdx + val) > this.statsData.labels.length) {
        // this.currentLabelIdx = 0
      } else if ((this.currentLabelIdx + val) < 0) {
        this.currentLabelIdx = this.statsData.labels.length - 1
      } else {
        this.currentLabelIdx += val
      }
    },
    getDevicename (deviceCode) {
      if (Object.hasOwn(this.liveData.relatedIdLookup, deviceCode)) {
        return this.liveData.relatedIdLookup[deviceCode]
      } else {
        return deviceCode
      }
    }
  },
  computed: {
    options () {
      return { ...this.defaultOptions, ...this.chartOptions }
    },
    chartData () {
      if (this.statistics.length > 1) {
        console.error('PIE GRAPH RECEIVED MORE THAN ONE STATISTIC CODE! (You can only show one of metric on a pie.)')
      }

      if (Object.hasOwn(this.statsData, 'stats')) {
        let relatedIds = Object.keys(this.statsData.stats[this.statistics[0]])
        let data = {
          labels: relatedIds.map(deviceCode => this.getDevicename(deviceCode)),
          datasets: []
        }
        // We can only display one day of data with this type of graph, so just use the first one.
        data.datasets.push({
          fieldId: this.statistics[0],
          label: this.statsData.labels[0],
          data: relatedIds.map(relatedId => this.statsData.stats[this.statistics[0]][relatedId][this.statsData.labels[this.currentLabelIdx]]),
          backgroundColor: relatedIds.map((val, idx) => ReportHelper.getReportColor(idx)),
          borderColor: relatedIds.map((val, idx) => ReportHelper.getReportColor(idx)),
          fill: true
        })
        return data
      } else {
        return []
      }
    }
  }
}
</script>

<style scoped>
.pie-graph-widget {
  position: relative;
  width: 100%;
  height: 100%;
  > div {
    height: 100%;
    position: relative;
  }
}

.control-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 1.2em;
}

@media print {
  .pie-graph-controls {
    display: none;
  }

  .control-row{
    color: black;
    justify-content: center;
  }
}
</style>
