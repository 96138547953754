<template>
<div class="report-summary-widget">
  <div v-if="report && this.reportParams && this.statsData" class="report-summary-container">
    <div class="vehicle-summary-text">
      <img class="sidebar-logo web-only" src="../../../assets/protekt-logo-slim-transparent.png">
      <img class="sidebar-logo print-only" src="../../../assets/protekt-logo-slim-BW-transparent.png">
    </div>
    <div class="top-level-statistic" >
      <div class="report-green-label">{{ report['name'] }}</div>
    </div>
    <div class="top-level-statistic">
      <div class="report-green-label">Report Date</div>
      <div class="report-white-text">{{ dateFormatFromString(new Date()) }}</div>
    </div>
    <div class="top-level-statistic">
      <div class="report-green-label">Start</div>
      <div class="report-white-text">{{ dateFormatFromString(reportParams['start_date']) }}</div>
    </div>
    <div class="top-level-statistic">
      <div class="report-green-label">End</div>
      <div class="report-white-text">{{ dateFormatFromString(reportParams['end_date']) }}</div>
    </div>
    <div class="top-level-statistic">
      <div class="report-green-label">Aggregation</div>
      <div class="report-white-text">{{ reportParams['time_grouping'] }}</div>
    </div>
  </div>
</div>
</template>

<script>
import WidgetBase from '@/components/stats/widgets/WidgetBase'
import moment from 'moment-timezone'

export default {
  name: 'WidgetDeviceSummary',
  extends: WidgetBase,
  props: {
    title: String,
    field_labels: Object,
    field_formatting: Object
  },
  data () {
    return {
      // NOTE: These are auto-populated by the report parent.
      statsData: null,
      liveData: null,
      related_ids: null,
      reportParams: null,
      report: null,
      // End Note
      defaultProps: [
        'device_imei',
        'device_name',
        'device_code',
        'registration',
        'icon',
        'hours_tracked',
        'odometer_reading',
        'plant_num'
      ]
    }
  },
  async mounted () {
    console.log(this.report)
  },
  methods: {
    dateFormatFromString (value) {
      if (value === 'None' || value === 0 || value == null) {
        return 'None'
      } else {
        let parsedDate = moment(value)
        if (parsedDate.isValid()) {
          return parsedDate.tz(this.statsData.timezone).format('DD-MM-YYYY')
        } else {
          // Unknown date format, or a different string (like "Totals"), so just return that
          return value
        }
      }
    },
    dateFormat (value) {
      if (value === 'None' || value === 0 || value == null) {
        return 'None'
      } else {
        return moment(moment.unix(value)).tz(this.statsData.timezone).format('DD-MM-YYYY')
      }
    },
    timeFormat (value) {
      if (value === 'None' || value === 0 || value == null) {
        return 'None'
      } else {
        return moment(moment.unix(value)).tz(this.statsData.timezone).format('HH:mm')
      }
    },
    dateTimeFormat (value) {
      if (value === 'None' || value === 0 || value == null) {
        return 'None'
      } else {
        return moment(moment.unix(value)).tz(this.statsData.timezone).format('DD-MM-YYYY HH:mm')
      }
    }
  },
  computed: {
  }
}
</script>

<style scoped lang="scss">
@import '@/variables';
@import "@/reports";

  .report-summary-widget {
    //margin-bottom: 2em;
    padding: 10px;
  }

  .report-summary-container {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    width: 96%;
  }

  .report-summary-container div{
    justify-content: space-between;
  }

  //.vehicle-report-stats{
  //  max-height: 40px;
  //  display: flex;
  //  flex-direction: column;
  //  flex-wrap: wrap;
  //  align-content: start;
  //  max-width: 5cm;
  //  text-align: right;
  //  margin-left: 1cm;
  //}
  .vehicle-report-stats div{
    display: flex;
    flex-direction: row;
    align-content: center;
    margin-right: 2em;
  }

  .green {
    font-size: 1.1em;
    margin-right: 2em;
  }

  .report-summary-text {
    color: white;
    font-size: 1.2em;
    flex-wrap: wrap;
  }

@media print {

  .top-level-statistic{
    max-width: 3cm;
  }

  .vehicle-summary-container {
    max-height: 2cm;
  }
  .sidebar-logo{
    height: 2cm;
    width: auto;
  }
  .vehicle-report-stats {
    max-height: 3cm;
  }
  .table-widget {
    max-width: 1000px;
  }
  .vehicle-summary-text {
    color: black;
  }
}

.fa-supersized{
  font-size: 4em;
}

</style>
