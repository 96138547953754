var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "report-page" },
    [
      _vm.taskId && !_vm.hideTaskPopup
        ? _c(
            "div",
            { staticClass: "task-popup" },
            [
              _c("TaskProgress", {
                attrs: { task_id: _vm.taskId },
                on: { finish: _vm.taskComplete }
              })
            ],
            1
          )
        : _vm._e(),
      _c("ReportComponent", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.statsData && _vm.liveData,
            expression: "statsData && liveData"
          }
        ],
        attrs: {
          report: _vm.report,
          reportParams: _vm.reportParams,
          relatedIds: _vm.relatedIds,
          liveData: _vm.liveData,
          statsData: _vm.statsData
        },
        on: {
          required_stats: _vm.getStatsData,
          required_device_props: _vm.getLiveData
        }
      }),
      _c(
        "b-modal",
        {
          staticClass: "modal-content",
          attrs: {
            id: "modal-export-data",
            centered: "",
            size: "m",
            "hide-footer": "",
            title: "Export Data"
          }
        },
        [
          _c(
            "div",
            { staticClass: "mb-3" },
            [
              _c("h4", [_vm._v("Data set to export:")]),
              _c("div", { staticClass: "green-divider" }),
              _c("b-form-radio-group", {
                attrs: { options: _vm.exportOptions },
                model: {
                  value: _vm.exportType,
                  callback: function($$v) {
                    _vm.exportType = $$v
                  },
                  expression: "exportType"
                }
              })
            ],
            1
          ),
          _c("DataExporter", {
            attrs: {
              data: _vm.exportData,
              fields: _vm.exportFields,
              modal_id: "modal-export-data"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }