<template>
<div class="device-summary-widget">
  <div v-if="statsData && liveData" class="vehicle-summary-container">
    <div>
      <div class="report-green-label">Device Name</div>
      <div class="report-white-text">{{deviceData.device_name}}</div>
    </div>
    <div>
      <div class="report-green-label">Device Code</div>
      <div class="report-white-text">{{deviceData.device_code}}</div>
    </div>
    <div>
      <div class="report-green-label">Plant Number</div>
      <div class="report-white-text">{{deviceData.plant_num}}</div>
    </div>
    <div>
      <div class="report-green-label">Vehicle Rego</div>
      <div class="report-white-text">{{deviceData.registration}}</div>
    </div>
    <div v-if="Object.hasOwn(deviceData, 'odometer_reading')">
      <div class="report-green-label">Odometer</div>
      <div class="report-white-text">{{numberFormat(deviceData.odometer_reading)}} Kms</div>
    </div>
    <div v-if="Object.hasOwn(deviceData, 'hours_tracked')">
      <div class="report-green-label">Running Time</div>
      <div class="report-white-text">{{numberFormat(deviceData.hours_tracked, 1)}} Hrs</div>
    </div>
  </div>
</div>
</template>

<script>
import WidgetBase from '@/components/stats/widgets/WidgetBase'
import moment from 'moment-timezone'

export default {
  name: 'WidgetDeviceSummary',
  extends: WidgetBase,
  props: {
    title: String,
    related_id_idx: [Number, String],
    field_labels: Object,
    field_formatting: Object
  },
  data () {
    return {
      // NOTE: These are auto-populated by the report parent.
      statsData: null,
      liveData: null,
      related_ids: null,
      reportParams: null,
      report: null,
      // End Note
      defaultProps: [
        'device_imei',
        'device_name',
        'device_code',
        'registration',
        'icon',
        'hours_tracked',
        'odometer_reading',
        'plant_num'
      ],
      formatters: {
        number: this.numberFormat,
        date: this.dateFormat,
        time: this.timeFormat,
        datetime: this.dateTimeFormat,
        fuel: this.fuelFormat,
        events: this.eventsFormat
      }
    }
  },
  async mounted () {
  },
  methods: {
    getRequiredDeviceProps () {
      return [...new Set(this.defaultProps.concat(this.device_props))]
    },
    getFormatter (fieldName) {
      if (this.field_formatting && Object.hasOwn(this.field_formatting, fieldName)) {
        let formatterType = this.field_formatting[fieldName].toLowerCase()
        if (Object.hasOwn(this.formatters, formatterType)) {
          return this.formatters[formatterType]
        } else {
          throw Error('Unknown Field Formatter Type: ' + formatterType)
        }
      } else {
        return this.numberFormat
      }
    },
    timeDurationFormat (value) {
      let alwaysShowHours = true
      let showSeconds = false
      if (isNaN(value)) {
        return value
      }

      // Initially from: https://stackoverflow.com/a/6313008/307363
      const totalInSeconds = parseInt(value, 10) * 3600
      let hours = Math.floor(totalInSeconds / 3600)
      let minutes = Math.floor((totalInSeconds - (hours * 3600)) / 60)
      let seconds = totalInSeconds - (hours * 3600) - (minutes * 60)

      if (alwaysShowHours || hours > 0){
        if (hours < 10) {
          hours = '0' + hours
        }
        hours = hours + ':'
      } else {
        hours = ''
      }
      if (minutes < 10) {
        minutes = '0' + minutes
      }
      if (showSeconds){
        if (seconds < 10) {
          seconds = '0' + seconds
        }
        seconds = ':' + seconds
      } else {
        seconds = ''
      }
      return hours + minutes + seconds
    },
    numberFormat (value, precision = 0) {
      if (value === null) {
        return 0.00
      } else if (isNaN(value)) {
        return value
      } else {
        if (value) {
          return parseFloat(value).toFixed(precision)
        } else {
          return 0.00
        }
      }
    },
    dateFormat (value) {
      if (value === 'None' || value === 0 || value == null) {
        return 'None'
      } else {
        return moment(moment.unix(value)).tz(this.statsData.timezone).format('DD-MM-YYYY')
      }
    },
    timeFormat (value) {
      if (value === 'None' || value === 0 || value == null) {
        return 'None'
      } else {
        return moment(moment.unix(value)).tz(this.statsData.timezone).format('HH:mm')
      }
    },
    dateTimeFormat (value) {
      if (value === 'None' || value === 0 || value == null) {
        return 'None'
      } else {
        return moment(moment.unix(value)).tz(this.statsData.timezone).format('DD-MM-YYYY HH:mm')
      }
    },
    fuelFormat (value) {
      if (value === 'None' || value === 0) {
        return '0.00L'
      } else {
        return `${value * 0.01}L`
      }
    },
    eventsFormat (value) {
      if (value === 'None' || value === 0 || value === null) {
        return 'None'
      } else {
        return `${value.toFixed(0)} Events`
      }
    },
    getStatLabel (stat) {
      if (this.field_labels && Object.hasOwn(this.field_labels, stat)) {
        return this.field_labels[stat]
      } else {
        return stat
      }
    },
    clickRelatedId (link) {
      let routeData = this.$router.resolve(link)
      window.open(routeData.href, '_blank')
    }
  },
  computed: {
    deviceData () {
      if (this.liveData && this.related_ids) {
        // let relatedIds = [...new Set([].concat(...Object.keys(this.statsData.stats).map(stat => Object.keys(this.statsData.stats[stat]))))]
        let imei = Object.keys(this.liveData.devices).find(imei => this.liveData.devices[imei].device_code === this.related_ids[this.related_id_idx])
        if (imei) {
          return this.liveData.devices[imei]
        } else {
          return null
        }
      } else {
        return null
      }
    },
    relatedLinks () {
      let links = {}
      if (this.liveData) {
        for (let device in this.liveData.devices) {
          links[this.liveData.devices[device].device_code] = `/device/${this.liveData.devices[device].device_imei}`
        }
      }
      return links
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/variables';

  .device-summary-widget {
    //margin-bottom: 2em;
    padding: 10px;
  }

  .vehicle-summary-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    //align-content: center;
  }

  .green-label {
  }

  .vehicle-summary-text {
    color: white;
  }

@media print {
  .table-widget {
    max-width: 1000px;
  }
  .vehicle-summary-text {
    color: black;
  }
}

</style>
