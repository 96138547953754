import moment from 'moment'
import { defaultTraceParameters } from '@/components/helpers/plotly_style'

export default class ReportDataHelper {
  /**
   * A private helper function that handles both "live data" (single-trace)
   * and "stats data" (multi-trace) modes, depending on `isStatsFormat`.
   *
   * @param {any} rawData - If `isStatsFormat=false`, this is a list of objects.
   *                        If `isStatsFormat=true`, it has { labels: [...], stats: {...} }.
   * @param {string} labelField - For live data, the name (or dot-path) of the label field.
   * @param {string} valueField - For live data, the name (or dot-path) of the value field.
   *                              For stats data, the key inside rawData.stats to retrieve device sub-objects.
   * @param {string} orderField - Optional. For live data, can have +/- prefix to indicate ascending/descending order.
   * @param {any} nullValue - Default value used when a field is missing or null.
   * @param {boolean} isStatsFormat - Controls which format logic to use.
   * @param {boolean} isDate - If `true`, interpret x-axis labels (from rawData.labels) as date strings, parse them,
   *                           and convert them to a Moment-based ISO string so Plotly displays a time-based axis.
   * @param {string} plotType - The type of plot to generate (e.g. 'bar', 'scatter', etc.)
   * @param {boolean} hideZeroValues - If `true`, exclude zero values from the plot.
   * @param valueMultiple - A multiplier to apply to all values (e.g. to convert units).
   */
  static _generateCategoricalPlotData(
    rawData,
    labelField,
    valueField,
    orderField,
    nullValue,
    isStatsFormat = false,
    isDate = false,
    plotType = 'bar',
    hideZeroValues = false,
    valueMultiple= 1
  ) {
    if (!rawData) {
      return [{ x: [], y: [], type: 'bar' }]
    }

    // === 1) If not stats format, build a SINGLE trace from list-of-objects ===
    if (!isStatsFormat) {
      let labels = []
      let values = []
      let orderValues = []
      let orderDirection = 1

      if (orderField) {
        if (orderField.startsWith('-')) {
          orderDirection = -1
          orderField = orderField.slice(1)
        } else if (orderField.startsWith('+')) {
          orderField = orderField.slice(1)
        }
      }

      // rawData is assumed to be an array of objects
      rawData.forEach(row => {
        const label = ReportDataHelper.getNestedField(row, labelField, nullValue)
        const val = ReportDataHelper.getNestedField(row, valueField, nullValue)
        labels.push(label)
        values.push(val * valueMultiple)

        // If there's an order field, collect it
        if (orderField) {
          const orderVal = ReportDataHelper.getNestedField(row, orderField, nullValue)
          orderValues.push(orderVal)
        }
      })

      // Sort if applicable
      if (orderField && orderValues.length > 0) {
        const indices = Array.from(orderValues.keys()).sort((a, b) => {
          return orderDirection * (orderValues[a] - orderValues[b])
        })
        labels = indices.map(i => labels[i])
        values = indices.map(i => values[i])
      }
      // Filter out zero values if requested - only if *all* values are zero
      if (hideZeroValues && values.every(v => v < 1e-6)) {
        return [{ x: [], y: [], type: plotType }]
      }

      // Single trace
      return [{
        x: labels,
        y: values,
        type: plotType
      }]
    }

    // === 2) If stats format, build MULTIPLE traces ===
    // rawData should have: { labels: [...], stats: { [valueField]: { DeviceID: { dateString: number } } } }
    const { labels, stats } = rawData
    if (!labels || !stats || !stats[valueField]) {
      return [{ x: [], y: [], type: 'bar' }]
    }

    const deviceData = stats[valueField] // e.g. DEVICE_DAILY_TOTAL_HOURS -> { "A0000004": { "2025/01/19": X, ... }, ... }

    // We'll return one trace per device
    const allTraces = []

    for (const deviceId of Object.keys(deviceData)) {
      const xVals = []
      const yVals = []

      labels.forEach(dateLabel => {
        // If date-based, parse and store an ISO date string
        if (isDate) {
          // parse "YYYY/MM/DD" with moment
          const m = moment(dateLabel, 'YYYY/MM/DD')
          // Store as an ISO-like string, e.g. "2025-01-19"
          xVals.push(m.format('YYYY-MM-DD'))
        } else {
          // Just store the original label
          xVals.push(dateLabel)
        }

        // The numeric value (or fallback)
        const val = deviceData[deviceId][dateLabel] ?? nullValue
        yVals.push(val * valueMultiple)
      })

      // Filter out zero values if requested - only if *all* values are zero
      if (hideZeroValues && yVals.every(v => v < 1e-6)) {
        continue
      }

      allTraces.push({
        x: xVals,
        y: yVals,
        type: 'bar',
        name: deviceId, // So each device has a legend entry
        ...defaultTraceParameters
      })
    }

    return allTraces
  }

  /**
   * For "live data" format (array of objects),
   * generating a single bar trace.
   * Same as your old generateCategoricalPlotLiveData, but reusing the helper.
   */
  static generateCategoricalPlotLiveData(rawData, labelField, valueField, orderField, nullValue,
    plotType = 'bar', hideZeroValues = false, valueMultiple=1) {
    return ReportDataHelper._generateCategoricalPlotData(
      rawData,
      labelField,
      valueField,
      orderField,
      nullValue,
      false, // isStatsFormat
      false,  // isDate
      plotType,
      hideZeroValues,
      valueMultiple
    )
  }

  /**
   * For "stats data" format:
   *   {
   *     labels: [ '2025/01/19', '2025/01/20', ... ],
   *     stats: {
   *       DEVICE_DAILY_TOTAL_HOURS: {
   *         "A0000004": { "2025/01/19": 1.2, "2025/01/20": 2.3 },
   *         "A0000005": { "2025/01/19": 0.8, "2025/01/20": 1.5 }
   *       }
   *     }
   *   }
   *
   * Generates multiple bar traces (one per device).
   * If `isDate=true`, will parse the date strings and return them as "YYYY-MM-DD".
   */
  static generateCategoricalPlotStatsData(rawData, valueField, nullValue, isDate = false,
    plotType = 'bar', hideZeroValues = false, valueMultiple=1) {
    return ReportDataHelper._generateCategoricalPlotData(
      rawData,
      null, // not needed
      valueField,
      null, // no ordering
      nullValue,
      true,  // isStatsFormat
      isDate, // parse & format labels as dates
      plotType, // bar or scatter
      hideZeroValues,
      valueMultiple
    )
  }

  /**
   * Utility to safely retrieve nested fields using dot notation.
   * e.g. getNestedField(obj, 'user.name', 'N/A')
   */
  static getNestedField(obj, fieldPath, defaultValue) {
    if (!obj || typeof fieldPath !== 'string') {
      return defaultValue
    }
    const parts = fieldPath.split('.')
    let current = obj
    for (const p of parts) {
      if (current[p] == null) {
        return defaultValue
      }
      current = current[p]
    }
    return current
  }
  /**
   * A private helper that takes an array of numbers or timestamps
   * and creates a histogram according to `groupType` ('numeric' or 'date')
   * and `groupParam` (bin size or date bucket).
   *
   * Returns an array with one trace: `[ { x: [...], y: [...], type: 'bar' } ]`.
   */
  static _generateHistogramPlotData(valuesArray, groupType, groupParam, nullValue, precision= 2) {
    console.log('STARTING HISTOGRAM PLOT - GENERIC')
    // If there's no data, return an empty bar trace
    if (!valuesArray || !valuesArray.length) {
      return [{ x: [], y: [], type: 'bar' }]
    }

    if (groupType === 'numeric') {
      // 1) Filter numeric and find min/max
      const numericValues = valuesArray.filter(v => typeof v === 'number')
      if (!numericValues.length) {
        return [{ x: [], y: [], type: 'bar' }]
      }

      const minVal = Math.min(...numericValues)
      const maxVal = Math.max(...numericValues)
      const binSize = Number(groupParam) || 1
      const binCount = Math.ceil((maxVal - minVal) / binSize)

      // Create bin containers
      const bins = {}
      for (let i = 0; i <= binCount; i++) {
        bins[i] = 0
      }

      // Increment counts in each bin
      numericValues.forEach(val => {
        const index = Math.floor((val - minVal) / binSize)
        bins[index]++
      })

      // Build label & count arrays
      // Build label & count arrays
      let binEntries = Object.keys(bins).map(binIndexStr => {
        const binIndex = Number(binIndexStr)
        const binStart = (minVal + binIndex * binSize).toFixed(precision) // Limit X-axis precision
        const binEnd = (parseFloat(binStart) + binSize - 1).toFixed(precision) // Ensure consistent number formatting
        return {
          binStart: parseFloat(binStart), // Convert to float for sorting
          label: `${binStart}🡒${binEnd}`,
          count: Number(bins[binIndex].toFixed(precision)) // Limit Y-axis precision
        }
      })

      // Sort bins by binStart (X value)
      binEntries.sort((a, b) => a.binStart - b.binStart)

      // Extract sorted X and Y arrays
      const x = binEntries.map(entry => entry.label)
      const y = binEntries.map(entry => entry.count)

      return [{
        x,
        y,
        type: 'bar',
        ...defaultTraceParameters
      }]
    } else if (groupType === 'date') {
      // 1) Gather timestamps (seconds since epoch) and bin them by groupParam
      //    groupParam in [minute, hour, day, week, month, year]
      const bucketMap = {}

      valuesArray.forEach(ts => {
        if (typeof ts === 'number') {
          const m = moment.unix(ts).local()
          if (['minute','hour','day','week','month','year'].includes(groupParam)) {
            m.startOf(groupParam)
            const label = m.format('YYYY-MM-DD HH:mm')
            if (!bucketMap[label]) {
              bucketMap[label] = 0
            }
            bucketMap[label]++
          }
        }
      })

      // 2) Sort the labels chronologically
      const sortedLabels = Object.keys(bucketMap).sort((a, b) => {
        const ma = moment(a, 'YYYY-MM-DD HH:mm')
        const mb = moment(b, 'YYYY-MM-DD HH:mm')
        return ma.valueOf() - mb.valueOf()
      })

      // 3) Build arrays for x & y
      const x = []
      const y = []
      sortedLabels.forEach(lbl => {
        x.push(lbl)
        y.push(Number(bucketMap[lbl].toFixed(precision))) // Limit Y-axis precision
      })

      return [{
        x,
        y,
        type: 'bar',
        ...defaultTraceParameters
      }]
    }

    // If groupType is unrecognized, return empty
    return [{
      x: [],
      y: [],
      type: 'bar',
      ...defaultTraceParameters
    }]
  }

  /**
   * For "live data": rawData is an array of objects.
   * We look up the numeric/timestamp field via `fieldName` for each row,
   * then bin them (numeric or date).
   */
  static generateHistogramPlotLiveData(
    rawData,
    fieldName,
    groupType, // 'numeric' or 'date'
    groupParam, // bin size (for numeric) OR e.g. 'hour','day','month' for date
    nullValue = null
  ) {
    console.log('STARTING HISTOGRAM PLOT - LIVE DATA')
    console.log(rawData, fieldName, groupType, groupParam, nullValue)

    if (!rawData || !rawData.length) {
      return [{ x: [], y: [], type: 'bar' }]
    }

    // Collect the relevant values from each row
    const valuesArray = []
    rawData.forEach(row => {
      const val = ReportDataHelper.getNestedField(row, fieldName, nullValue)
      if (val !== nullValue) {
        valuesArray.push(val)
      }
    })

    // Delegate to the private function
    return ReportDataHelper._generateHistogramPlotData(
      valuesArray,
      groupType,
      groupParam,
      nullValue
    )
  }

  /**
   * For "stats data": rawData has structure like:
   * {
   *   labels: ['2025/01/19','2025/01/20',...],
   *   stats: {
   *     SOME_FIELD: {
   *       Device1: { '2025/01/19': 123, '2025/01/20': 456, ... },
   *       Device2: { '2025/01/19': 789, '2025/01/20': 101, ... },
   *       ...
   *     }
   *   }
   *   ...
   * }
   *
   * We'll gather all numeric values from all devices/dates for the given `valueField`,
   * then produce a single histogram trace for that distribution.
   *
   * If it needs to be multi-trace (one histogram per device), you'd adapt to produce multiple traces.
   */
  static generateHistogramPlotStatsData(
    rawData,
    valueField,
    groupType, // 'numeric' or 'date'
    groupParam,
    nullValue = null
  ) {
    console.log('STARTING HISTOGRAM PLOT - STATS DATA')
    console.log(rawData, valueField, groupType, groupParam, nullValue)

    if (!rawData || !rawData.stats || !rawData.stats[valueField]) {
      return [{ x: [], y: [], type: 'bar' }]
    }

    const { labels, stats } = rawData
    // stats[valueField] => e.g. { Device1: { '2025/01/19': 123, ... }, Device2: ... }

    const distributionValues = []
    const deviceDict = stats[valueField]
    // For each device, for each date in labels, gather numeric values
    Object.keys(deviceDict).forEach(deviceId => {
      labels.forEach(lbl => {
        // e.g. deviceDict[deviceId][lbl] => numeric or timestamp
        const val = deviceDict[deviceId][lbl] ?? nullValue
        if (val !== nullValue) {
          distributionValues.push(val)
        }
      })
    })

    // Now we have a single array of numeric or timestamp values
    // Bin them with the same logic as the live data method
    return ReportDataHelper._generateHistogramPlotData(
      distributionValues,
      groupType,
      groupParam,
      nullValue
    )
  }
}
