<template>
<div class="widget-trend-line-graph" ref="base">
  <div class="background-graph">
    <LineGraph v-if="statsData" :chart-data="chartData" :options="options"></LineGraph>
  </div>
  <b-tooltip :target="$refs.base" triggers="hover" v-if="$refs.base && tooltipText">
    {{tooltipText}}
  </b-tooltip>
  <div class="number-overlay" v-if="statsData" :style="{'font-size': numberFontSize}">
    <div>{{foregroundNumber}}
      <div class="trend-indicator" v-if="statsData">
        <i  v-if="lastMetricChange < 0"
            class="fa fa-caret-down trend-down"
            v-b-tooltip
            :title="metricTooltipText"
        ></i>
        <i  v-if="lastMetricChange === 0"
            class="fa fa-minus trend-same"
            v-b-tooltip
            :title="metricTooltipText"
        ></i>
        <i v-if="lastMetricChange > 0"
           class="fa fa-caret-up trend-up"
           v-b-tooltip
           :title="metricTooltipText"
        ></i>
      </div>
    </div>
    <div class="metric-suffix">{{metrixSuffixText}}</div>
  </div>

</div>
</template>

<script>
/***
 *    Trend Line Widget
 * A small square widget that displays an axes-less line graph as a background, with a large number metric in the
 * foreground (and an indicator if the most recent change was up or down). Only displays a SINGLE Statistic or Prop.
 ***/
import WidgetBase from '@/components/stats/widgets/WidgetBase'
import LineGraph from '@/components/stats/charts/LineGraph'
import ReportHelper from '@/components/helpers/ReportHelper'
import {MetricFormatter as mf} from '@/components/helpers/MetricFormatter'

export default {
  name: 'WidgetTrendLineGraph',
  components: { LineGraph },
  extends: WidgetBase,
  props: {
    title: String,
    statistics: Array,
    chart_options: Object,
    aggregration_mode: String, // How to aggregate stats (SUM or COUNT)
    metric_suffix: String,
    trend_label: String,
    formatter: String,
    description: String
  },
  data () {
    return {
      // NOTE: These are auto-populated by the report parent.
      statsData: null,
      liveData: null,
      related_ids: null,
      reportParams: null,
      report: null,
      // End Note
      defaultOptions: {
        layout: {
          padding: '3'
        },
        responsive: true,
        maintainAspectRatio: true,
        legend: {
          display: false
        },
        scales: {
          yAxes: [{
            display: false,
            ticks: {
              suggestedMin: 0
            }
          }],
          xAxes: [{
            display: false
          }]
        }
      },
      formatters: {
        number: (v) => mf.numberFormat(v, 2),
        integer: (v) => mf.numberFormat(v, 0),
        date: (v) => mf.localDate(v * 1000), // From seconds as default, as that's what the server returns
        time: (v) => mf.localTime(v * 1000), // From seconds as default, as that's what the server returns
        date_from_seconds: (v) => mf.localDate(v * 1000),
        time_from_seconds: (v) => mf.localTime(v * 1000),
        date_from_milliseconds: mf.localDate,
        time_from_milliseconds: mf.localTime,
        datetime: mf.localDateTime,
        fuel: mf.fuelFormat,
        events: this.integerFormatDefaultZero,
        time_duration: (v) => mf.secondsToTime(v * 3600), // From hours as default, as that's what the server returns
        time_duration_from_hours: (v) => mf.secondsToTime(v * 3600),
        time_duration_from_seconds: mf.secondsToTime,
      }
    }
  },
  methods: {
    numberFormat (value) {
      if (value) {
        return value.toFixed(2)
      } else {
        return 0
      }
    },
    sumArray (array) {
      return array.reduce((a, b) => a + b, 0)
    }
  },
  computed: {
    mode () {
      if (this.aggregration_mode) {
        return this.aggregration_mode
      } else {
        return 'SUM'
      }
    },
    options () {
      if (!this.chart_options) {
        return this.defaultOptions
      } else {
        return { ...this.defaultOptions, ...this.chart_options }
      }
    },
    numberFontSize () {
      return 6 - (String(this.foregroundNumber).length / 1.5) + 'em'
    },
    chartData () {
      if (this.statistics.length > 1) {
        console.error('TREND LINE WIDGET RECEIVED MORE THAN ONE STATISTIC CODE! (You can only show one of metric on a pie.)')
      }
      if (Object.hasOwn(this.statsData, 'stats')) {
        let data = {
          labels: this.statsData.labels,
          datasets: [{
            label: this.trend_label ? this.trend_label : this.statistics[0],
            data: this.statsData.labels.map(date => {
              // Grab all of the first data for the first statistic in our list (there should only be one anyway)
              let dateData = Object.keys(this.statsData.stats[this.statistics[0]]).map(relatedId => {
                return parseFloat(this.statsData.stats[this.statistics[0]][relatedId][date])
              })
              if (this.mode.toUpperCase() === 'SUM') {
                return this.sumArray(dateData)
              } else {
                return dateData.length
              }
            }),
            fill: false,
            backgroundColor: '#999999',
            borderColor: '#999999',
            tension: 0.1
          }]
        }
        return data
      } else {
        return []
      }
    },
    foregroundNumber () {
      if (this.formatter && Object.hasOwn(this.formatters, this.formatter)) {
        return this.formatters[this.formatter](this.chartData.datasets[0].data.slice(-1)[0])
      } else {
        return this.chartData.datasets[0].data.slice(-1)[0].toFixed(0)
      }
    },
    lastMetricChange () {
      if (this.chartData.datasets[0].data.length > 1) {
        let vals = this.chartData.datasets[0].data.slice(-2)
        return mf.numberFormat(vals[1] - vals[0], 2)
      } else {
        return 0
      }
    },
    metricTooltipText() {
      return `Trend Change: ${this.lastMetricChange}`
    },
    metrixSuffixText () {
      if (this.metric_suffix) {
        return this.metric_suffix
      } else {
        return ''
      }
    },
    tooltipText () {
      return this.description
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/variables';

.widget-trend-line-graph {
  position: relative;
  width: 150px;
  height: 150px;
}

.number-overlay {
  font-weight: bold;
  position: absolute;
  display: flex;
  flex-flow: column wrap;
  align-content: center;
  justify-content: center;
  //transform: translate(-50%, -50%);
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);
  margin-bottom: 0;
  height: 100%;
  top: -16px;
  left: 50%;
  transform: translateX(-50%);
}

.metric-suffix {
  position: absolute;
  bottom: -18px;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 32px;
}

.trend-indicator{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -0.65em;
  font-size: 0.5em;
}

.trend-down {
  color: #900000;
  -webkit-text-stroke: 1px #e50000;
}

.trend-up {
  color: #2e72ff;
  -webkit-text-stroke: 1px #022673;
}

.trend-same {
  font-size: 0.8em;
}

@media print {
  .number-overlay {
    -webkit-text-stroke: 2px #595959;
  }
}

</style>
