<template>
<div class="table-widget">
  <div v-if="!statsNonZero && !device_props">
    <h4 class="no-data"><i class="fa fa-exclamation-triangle mr-2"/>No data recorded in time period.</h4>
  </div>
  <b-table v-if="liveData && statsData " :items="tableRows" :fields="tableFields" bordered hover striped small
           :tbody-tr-class="rowStyleClass"
           thead-tr-class="report-widget-table-header"
           thead-class="report-widget-table-header"
           :sort-by="sort_by"
           :sort-desc="sort_desc"
  >
    <template #thead-top="statsData" v-if="tableHeaders">
      <b-tr>
        <b-th v-for="(header, idx) of tableHeaders" v-bind:key="idx" :colspan="header.colspan">
          {{header.title}}
        </b-th>
      </b-tr>
    </template>
    <template #table-colgroup="scope">
      <col
        v-for="field in scope.fields"
        :key="field.key"
        :style="{ width: field.width ? field.width : '100%' }"
      >
    </template>

    <template v-slot:cell(related_id)="row">
      <div v-if="Object.hasOwn(relatedLinks, row.item.related_id)" class="fake-link"
      @click="clickRelatedId(relatedLinks[row.item.related_id])"
           v-b-tooltip title="You can view this device's page."
      >
        <DeviceAvatar :device="devicesByCode[row.item.related_id]" ></DeviceAvatar>
<!--        <div>{{liveData.relatedIdLookup[row.item.related_id]}}</div>-->
<!--        ({{row.item.related_id}})-->
      </div>
      <div v-else>{{row.item.related_id}}</div>
    </template>

    <template v-slot:cell()="{item, value, field, formatter}">
      <div v-if="getFormatterType(field.key) === 'event'">
        <EventAvatar v-if="value !== ''" :event="value" :device_imei="getDeviceIMEI(value.device_id)" enable_link
          v-b-tooltip title="Open Event in New Tab"
        />
      </div>
      <div v-else>
        {{value}}
      </div>
    </template>
  </b-table>
  <div v-if="statsData && !statsData.stats && !device_props">
    <h3>No Results</h3>
  </div>

</div>
</template>

<script>
import WidgetBase from '@/components/stats/widgets/WidgetBase'
import moment from 'moment'
import { MetricFormatter as mf } from '@/components/helpers/MetricFormatter'
import EventAvatar from '@/components/events/EventAvatar.vue'
import DeviceAvatar from '@/components/device/DeviceAvatar.vue'


export default {
  name: 'WidgetDataTable',
  components: {DeviceAvatar, EventAvatar},
  extends: WidgetBase,
  props: {
    title: String,
    statistics: Array,
    stat_type: String,
    related_id_idx: [Number, String],
    related_id_title: String,
    related_id_type: String,
    table_mode: String,
    date_axis: String,
    field_labels: Object,
    field_formatting: Object,
    show_device_totals: Boolean, // NOTE: This only working VERTICAL mode (it would look ugly in Horizontal)
    show_row_header: Boolean,
    device_totals_ignore: Array, // Statistics that should NOT be totaled (i.e. Start times)
    row_filter_func: Function, // Display only rows which meet a given criteria
    hide_fields: Array,
    sort_by: String, // Field to sort by
    sort_desc: Boolean,
    hide_live_data_date: Boolean
  },
  data () {
    return {
      // NOTE: These are auto-populated by the report parent.
      statsData: null,
      liveData: null,
      related_ids: null,
      reportParams: null,
      report: null,
      // End Note
      defaultProps: ['device_imei', 'device_name', 'device_code', 'device_id', 'icon', 'device_type'],
      defaultTotalIgnores: ['DEVICE_DAILY_START_TIME', 'DEVICE_DAILY_END_TIME'],
      formatters: {
        number: (v) => mf.numberFormat(v, 2),
        integer: (v) => mf.numberFormat(v, 0),
        date: (v) => mf.localDate(v * 1000), // From seconds as default, as that's what the server returns
        time: (v) => mf.localTime(v * 1000), // From seconds as default, as that's what the server returns
        date_from_seconds: (v) => mf.localDate(v * 1000),
        time_from_seconds: (v) => mf.localTime(v * 1000),
        date_from_milliseconds: mf.localDate,
        time_from_milliseconds: mf.localTime,
        datetime: mf.localDateTime,
        fuel: mf.fuelFormat,
        events: this.integerFormatDefaultZero,
        time_duration: (v) => mf.secondsToTime(v * 3600), // From hours as default, as that's what the server returns
        time_duration_from_hours: (v) => mf.secondsToTime(v * 3600),
        time_duration_from_seconds: mf.secondsToTime,
        event: (x) => x // This is actually implemented using a custom slow in the template
      }
    }
  },
  async mounted () {
    if (this.date_axis === 'horizontal' && this.show_device_totals) {
      console.error('DataTable Widget: "show_device_totals" Option is not supported when date_axis is "horizontal"!')
    }
  },
  methods: {
    integerFormatDefaultZero (value) {
      return mf.integerFormat(value, 0)
    },
    getRequiredDeviceProps () {
      return [...new Set(this.defaultProps.concat(this.device_props))]
    },
    getFormatterType (fieldName) {
      if (this.field_formatting && Object.hasOwn(this.field_formatting, fieldName)) {
        return this.field_formatting[fieldName].toLowerCase()
      } else {
        return null
      }
    },
    getFormatter (fieldName) {
      if (this.field_formatting && Object.hasOwn(this.field_formatting, fieldName)) {
        let formatterType = this.field_formatting[fieldName].toLowerCase()
        if (Object.hasOwn(this.formatters, formatterType)) {
          return this.formatters[formatterType]
        } else {
          throw Error('Unknown Field Formatter Type: ' + formatterType)
        }
      } else {
        return this.formatters.number
      }
    },
    /***
     * Returns a Field Label alias for a given field key (device_prop or statistic type)
     * @param stat
     * @return {*}
     */
    getStatLabel (stat) {
      if (this.field_labels && Object.hasOwn(this.field_labels, stat)) {
        return this.field_labels[stat]
      } else {
        return stat
      }
    },
    clickRelatedId (link) {
      let routeData = this.$router.resolve(link)
      window.open(routeData.href, '_blank')
    },
    rowStyleClass (item) {
      if (item.isTotal) {
        return 'report-widget-table-body-total'
      } else {
        return 'report-widget-table-body'
      }
    },
    /***
     * If a 'row_filter_func' prop has been provided, test each row against it to see if it should be displayed. This
     * filter is applied after all data for each row has been compiled so the row properties should correspond to the
     * statistic names and device_props that have been selected for the table.
     * @return {boolean}
     */
    filterRows (row) {
      let device = null
      if (Object.hasOwn(row, 'related_id')) {
        device = this.getDeviceByCode(row.related_id)
      }
      if (this.row_filter_func) {
        return this.row_filter_func(row, device)
      } else {
        return true
      }
    },
    /***
     * If a 'hide_fields' value has been specified this function will exclude any field key which is included in the
     * given list of fields. Field keys are based on statistics or device_props so either should be usable.
     * @param field
     * @return {boolean}
     */
    filterFields (field) {
      if (this.hide_fields) {
        return !this.hide_fields.includes(field.key)
      } else {
        return true
      }
    },
    getDeviceIMEI (deviceId) {
      return Object.values(this.liveData.devices).find(d => d.device_id === deviceId).device_imei
    },
    getDevice (deviceId) {
      return Object.values(this.liveData.devices).find(d => d.device_id === deviceId)
    },
    getDeviceByCode (deviceCode) {
      return Object.values(this.liveData.devices).find(d => d.device_code === deviceCode)
    }
  },
  computed: {
    devicesByCode () {
      if (this.liveData) {
        let devices = {}
        Object.values(this.liveData.devices).forEach((device) => { devices[device.device_code] = device })
        return devices
      } else {
        return {}
      }
    },
    /***
     * Table Fields determines which columns will be present in the table. Oddly when Bootstrap tables talk about
     * 'headers', they are actually talking about a row that is ABOVE the row of field names at the top of the table.
     * Hence 'fields' is probably wha you would normally think of as headers.
     *
     *  date_axis: Control which axis the Statistical Dates are displayed on.
     *  For 'horizontal' tables, the Dates will be placed in headers ABOVE the table's fields.
     *  for 'vertical' the dates will be placed at the start of each row.
     *
     * @return {*[]}
     */
    tableFields () {
      // Find the longest id label and use that for the width calculation
      // let relatedIds = [...new Set([].concat(...this.statistics.map(stat => Object.keys(this.statsData.stats[stat]))))]
      let relatedIdWidth = Math.max(...this.related_ids.map(x => x.length)) + 10

      let fields = []

      if (this.show_row_header) {
        fields.push({ key: 'related_id',
          label: this.related_id_title ? this.related_id_title : 'Device',
          sortable: true,
          width: relatedIdWidth + 'em'
        })
      }

      if (this.date_axis === 'horizontal') {
        // Horizontal Date Mode (Date is a header)
        if (this.device_props) {
          fields = fields.concat(this.device_props.map(deviceProp => {
            return {
              key: deviceProp,
              label: this.getStatLabel(deviceProp),
              sortable: true,
              formatter: this.getFormatter(deviceProp),
              width: this.getStatLabel(deviceProp).length + 6 + 'ch'
            }
          }))
        }

        this.statsData.labels.forEach(date => {
          this.statistics.forEach(stat => {
            fields.push({
              key: `${date}-${stat}`,
              label: this.getStatLabel(stat),
              sortable: true,
              formatter: this.getFormatter(stat),
              width: this.getStatLabel(stat).length + 6 + 'ch'
            })
          })
        })
      } else {
        // Vertical Date Mode (Date is a field)
        // Skip this if we aren't doing Stats for this table (order is important so we need to do this before props)
        if (this.statistics) {
          fields.push(
            {
              key: 'date',
              label: 'Date',
              sortable: true,
              formatter: (v) => v !== 'Totals' ? mf.localDate(v) : v,
              width: '12ch'
            }
          )
        }
        if (this.device_props) {
          fields = fields.concat(this.device_props.map(deviceProp => {
            return {
              key: deviceProp,
              label: this.getStatLabel(deviceProp),
              sortable: true,
              formatter: this.getFormatter(deviceProp),
              width: this.getStatLabel(deviceProp).length + 6 + 'ch'
            }
          }))
        }
        if (this.statistics) {
          this.statistics.forEach(stat => {
            fields.push({
              key: `${stat}`,
              label: this.getStatLabel(stat),
              sortable: true,
              formatter: this.getFormatter(stat),
              width: this.getStatLabel(stat).length + 6 + 'ch'
            })
          })
        }
      }
      fields = fields.filter((field) => this.filterFields(field))
      return fields
    },
    tableHeaders () {
      // Headers appear above the table (the 'fields' are the headers within the tables)
      let headers = []
      if (this.date_axis === 'horizontal' && this.statistics && this.statistics.length) {
        headers.push({
          title: '',
          colspan: 1,
          stickyColumn: true
        })
        // Add a header for any 'Live' device data
        if (this.device_props && !this.hide_live_data_date) {
          headers.push({
            title: 'Live',
            colspan: this.device_props.length
          })
        }
        this.statsData.labels.forEach(date => {
          headers.push({
            title: date,
            colspan: this.statistics.length
          })
        })
      }
      return headers
    },
    tableRows () {
      let rows = []
      // If a related_id_idx has been provided, only show data for that device
      let relatedIds = this.related_ids
      if (this.related_id_idx !== null && this.related_id_idx !== undefined) {
        relatedIds = [this.related_ids[this.related_id_idx]]
      }
      if (this.date_axis === 'horizontal') {
        for (let id of relatedIds) {
          let row = {
            'related_id': id
          }
          if (this.device_props && this.liveData) {
            this.device_props.forEach(deviceProp => {
              if (Object.hasOwn(this.devicesByCode, id)) {
                row[deviceProp] = this.devicesByCode[id][deviceProp]
              } else {
                row[deviceProp] = 'N/A'
              }
            })
          }

          this.statsData.labels.forEach(date => {
            this.statistics.forEach(stat => {
              row[`${date}-${stat}`] = this.statsData.stats[stat][id][date]
            })
          })
          rows.push(row)
        }
      } else {
        for (let id of relatedIds) {
          let rowTemplate = {
            'related_id': id
          }
          // Add Live Data Row (this might look weird?? We probably don't want to mix data in this format)
          if (this.device_props && this.liveData && !this.hide_live_data_date) {
            let rowLive = { ...rowTemplate, date: 'Live' }
            this.device_props.forEach(deviceProp => {
              if (Object.hasOwn(this.devicesByCode, id)) {
                rowLive[deviceProp] = this.devicesByCode[id][deviceProp]
              } else {
                rowLive[deviceProp] = 'N/A'
              }
            })
            rows.push(rowLive)
          }
          // Tables using only liveData we don't need to add the statData labels
          if (this.statistics) {
            this.statsData.labels.forEach(date => {
              let row = {
                ...rowTemplate,
                date: date
              }
              this.statistics.forEach(stat => {
                row[stat] = this.statsData.stats[stat][id][date]
              })
              rows.push(row)
            })
          }
          if (this.show_device_totals) {
            let deviceTotalRow = {
              'related_id': id,
              'date': 'Totals',
              isTotal: true
            }
            this.statistics.forEach(stat => {
              if (!this.statsToNotTotal.includes(stat)) {
                deviceTotalRow[stat] = this.statsData.labels.reduce((total, date) =>
                  total + this.statsData.stats[stat][id][date]
                , 0)
              }
            })
            rows.push(deviceTotalRow)
          }
        }
      }
      rows = rows.filter((row) => this.filterRows(row))
      return rows
    },
    relatedLinks () {
      let links = {}
      if (this.liveData) {
        for (let device in this.liveData.devices) {
          links[this.liveData.devices[device].device_code] = `/device/${this.liveData.devices[device].device_imei}`
        }
      }
      return links
    },
    statsToNotTotal () {
      if (this.device_totals_ignore) {
        return this.device_totals_ignore
      } else {
        return this.defaultTotalIgnores
      }
    },
    statsNonZero () {
      if (this.statsData === null) {
        return false
      }
      // Return True if any of the data points are non-zero
      let id = this.related_ids[this.related_id_idx]
      for (let stat in this.statsData.stats) {
        for (let date in this.statsData.stats[stat][id]) {
          if (this.statsData.stats[stat][id][date]) {
            // console.log(`Non-Zero Stat Found: ${stat} ${id} ${date} = ${this.statsData.stats[stat][id][date]}`)
            // console.log(this.statsData.stats)
            // console.log(this.liveData)
            // console.log('------------------')
            return true
          }
        }
      }
      return false
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/variables';
@import "@/reports";

  .table-widget {
    position: relative;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
  }

  h3 {
    color: white;
  }

.row-icon {
  color: $theme-color-primary-2;
  margin-left: 1em;
}

.table td {
  color: black !important;
}

.no-data {
  color: white;
}

@media print {
  .table-widget {
    max-width: 100%;
  }

}

</style>
