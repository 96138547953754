var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "report-component" },
    [
      _c(_vm.currentView, {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.statsData && _vm.liveData,
            expression: "statsData && liveData"
          }
        ],
        ref: "reportComponent",
        tag: "component",
        attrs: {
          report: _vm.report,
          reportParams: _vm.reportParams,
          statsData: _vm.statsData,
          liveData: _vm.liveData,
          relatedIds: _vm.relatedIds
        },
        on: {
          required_stats: _vm.requiredStats,
          required_device_props: _vm.requiredDeviceProps
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }