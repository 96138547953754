var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "device-summary-widget" }, [
    _vm.statsData && _vm.liveData
      ? _c("div", { staticClass: "vehicle-summary-container" }, [
          _c("div", [
            _c("div", { staticClass: "report-green-label" }, [
              _vm._v("Device Name")
            ]),
            _c("div", { staticClass: "report-white-text" }, [
              _vm._v(_vm._s(_vm.deviceData.device_name))
            ])
          ]),
          _c("div", [
            _c("div", { staticClass: "report-green-label" }, [
              _vm._v("Device Code")
            ]),
            _c("div", { staticClass: "report-white-text" }, [
              _vm._v(_vm._s(_vm.deviceData.device_code))
            ])
          ]),
          _c("div", [
            _c("div", { staticClass: "report-green-label" }, [
              _vm._v("Plant Number")
            ]),
            _c("div", { staticClass: "report-white-text" }, [
              _vm._v(_vm._s(_vm.deviceData.plant_num))
            ])
          ]),
          _c("div", [
            _c("div", { staticClass: "report-green-label" }, [
              _vm._v("Vehicle Rego")
            ]),
            _c("div", { staticClass: "report-white-text" }, [
              _vm._v(_vm._s(_vm.deviceData.registration))
            ])
          ]),
          Object.hasOwn(_vm.deviceData, "odometer_reading")
            ? _c("div", [
                _c("div", { staticClass: "report-green-label" }, [
                  _vm._v("Odometer")
                ]),
                _c("div", { staticClass: "report-white-text" }, [
                  _vm._v(
                    _vm._s(_vm.numberFormat(_vm.deviceData.odometer_reading)) +
                      " Kms"
                  )
                ])
              ])
            : _vm._e(),
          Object.hasOwn(_vm.deviceData, "hours_tracked")
            ? _c("div", [
                _c("div", { staticClass: "report-green-label" }, [
                  _vm._v("Running Time")
                ]),
                _c("div", { staticClass: "report-white-text" }, [
                  _vm._v(
                    _vm._s(_vm.numberFormat(_vm.deviceData.hours_tracked, 1)) +
                      " Hrs"
                  )
                ])
              ])
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }