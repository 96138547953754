<template>
  <div class="report-page">
    <div v-if="taskId && !hideTaskPopup" class="task-popup">
      <TaskProgress :task_id="taskId" @finish="taskComplete"></TaskProgress>
    </div>
    <ReportComponent v-show="statsData && liveData"
      :report="report"
      :reportParams="reportParams"
      :relatedIds="relatedIds"
      :liveData="liveData"
      :statsData="statsData"
                     @required_stats="getStatsData"
                     @required_device_props="getLiveData"
      ></ReportComponent>

    <b-modal id="modal-export-data" centered class="modal-content" size="m" hide-footer
             :title="'Export Data'">
      <div class="mb-3">
        <h4>Data set to export:</h4>
        <div class="green-divider"></div>
        <b-form-radio-group v-model="exportType" :options="exportOptions">
        </b-form-radio-group>
      </div>
      <DataExporter :data="exportData" :fields="exportFields" modal_id="modal-export-data"></DataExporter>
    </b-modal>
  </div>
</template>
<script>

import Vue from 'vue'
import * as DataProvider from '@/components/helpers/DataProvider'
import * as ErrorHelper from '@/components/helpers/ErrorHelper'
import * as AlertHelper from '@/components/helpers/AlertHelper'
import statsDisplayTable from '@/components/stats/StatsDisplayTable'
import ReportPageBase from '@/components/stats/widgets/ReportPageParent'
import ReportHelper from '@/components/helpers/ReportHelper'
import TaskProgress from '@/components/stats/TaskProgress.vue'
import DataExporter from '@/components/DataExporter.vue'
import ReportComponent from '@/components/stats/ReportComponent.vue'

Vue.component('default', {
  template: '<div>Loading Report</div>'
})

export default {
  name: 'ReportPage',
  components: { ReportComponent, DataExporter, TaskProgress },
  props: {
    report: Object,
    startDate: String,
    endDate: String,
    dateGrouping: String,
    timezone: String,
    related_ids: Array,
    related_type: String,
    reportTemplate: String
  },
  data () {
    return {
      currentView: 'default',
      testTemplate: "<div><h3>Report Loading...</h3></div>",
      customTemplate: null,
      reportTemplateIdx: 0,
      taskId: null,
      hideTaskPopup: false,
      liveData: null,
      statsData: null,
      exportType: 'live',
      exportOptions: [
        {
          text: 'Live Device Data',
          value: 'live'
        },
        {
          text: 'Statistical Data',
          value: 'stats'
        }
      ]
    }
  },
  mounted () {
    if (this.reportTemplate) {
      console.log('Report Page Component: Component Display Mode (Editor)')
      this.currentView = this.generateComponent(this.reportTemplate)
    } else if (this.report) {
      console.log('Report Page Component: Component Generation Mode')
      if (Object.hasOwn(this.report.data, 'template')) {
        // this.generateComponent('ReportTemp', this.report.data.template)
        console.log('Report Template: ', this.testTemplate)
        this.currentView = this.generateComponent(this.report.data.template)
      } else {
        ErrorHelper.displayGeneralErrorToast('Report Template Not Found!', 'Error Loading Report')
      }
    }
  },
  methods: {
    generateComponent (template) {
      this.reportTemplateIdx += 1
      let componentId = `report-page-${this._uid}-${this.reportTemplateIdx}`
      Vue.component(componentId, {
        extends: ReportPageBase,
        template: template,
        components: {
          statsDisplayTable
        },
        data () {
          return {
            number: 9999
          }
        }
      })
      return componentId
    },
    async getStatsData (requiredStats) {
      let resp = await DataProvider.getStatsByParams(this.report.id, requiredStats, this.reportParams)
      if (resp.success) {
        if (Object.hasOwn(resp.data, 'task_created')) {
          this.$emit('task_created', resp.data.task_created.id)
          this.taskId = resp.data.task_created.id
        }
      } else {
        ErrorHelper.displayDataErrorToast(resp)
      }
    },
    async getLiveData (requiredDeviceProps) {
      let deviceData = await this.getDeviceProps(requiredDeviceProps)

      this.liveData = {
        relatedIdLookup: this.getRelatedIdLookup(deviceData),
        devices: deviceData,
        deviceProps: requiredDeviceProps
      }
      if (this.statsData && this.liveData) {
        this.dataReady()
      }
    },
    async getDeviceProps (requiredDeviceProps) {
      let resp = await DataProvider.getDeviceList(requiredDeviceProps)
      if (resp.success) {
        return resp.data
      } else {
        ErrorHelper.displayDataErrorToast(resp)
      }
    },
    taskComplete (status, data) {
      console.log('TASK COMPLETE!', status, data)
      if (status === 'COMPLETE') {
        this.statsData = data
        this.hideTaskPopup = true
        if (this.statsData && this.liveData) {
          this.dataReady()
        }
      }
    },
    dataReady () {
      console.log('DATA READY!')
      // Data is available to be exported.
      console.log('statsData: ', this.statsData)
      console.log('LiveData: ', this.liveData)
      this.$emit('data_ready')
    },
    getRelatedIdLookup (deviceData) {
      // TODO - Add support for Users here when we update to Corp Reporting.
      let names = {}
      for (let device in deviceData) {
        names[deviceData[device].device_code] = deviceData[device].device_name
      }
      return names
    }
  },
  watch: {
    reportTemplate (newTemplate) {
      /***
       * Handle change of template, by generating a new component (component templates are static, so we need to
       * make a new one each time it changes).
       *
       * Note: This is probably inefficient as hell, but is really only used on the admin side of things to preview
       * template changes dynamically.
       */
      console.log('Report Page: Updating with Custom Template')
      this.customTemplate = newTemplate
      this.currentView = this.generateComponent(this.customTemplate)
    }
  },
  computed: {
    reportParams () {
      return ReportHelper.getReportDatesParamsFromDates(this.startDate, this.endDate, this.dateGrouping, this.timezone,
        this.related_ids, this.related_type)
    },
    exportFields () {
      if (!this.statsData || !this.liveData) {
        return null
      }
      let fields
      if (this.exportType === 'live') {
        fields = this.liveData.deviceProps
      } else {
        fields = ['date', 'device_code'].concat(Object.keys(this.statsData.stats))
      }
      console.log('Fields: ', fields)
      return fields
    },
    exportData () {
      if (!this.statsData || !this.liveData) {
        return null
      }
      let exportData = []
      if (this.exportType === 'live') {
        for (let imei in this.liveData.devices) {
          exportData.push(this.liveData.devices[imei])
        }
      } else {
        let stats = Object.keys(this.statsData.stats)
        for (let date of this.statsData.labels) {
          Object.keys(this.statsData.stats[stats[0]]).forEach(deviceCode => {
            let row = {
              date: date,
              device_code: deviceCode
            }
            stats.forEach(statId => {
              row[statId] = this.statsData.stats[statId][deviceCode][date]
            })
            exportData.push(row)
          })
        }
      }
      console.log('LiveData Export: ', exportData)
      return exportData
    },
    relatedIds () {
      // This is probably overkill. We're checking all the stats in case there are some missing devices (which shouldn't
      // happen).
      console.log("Stats data:")
      console.log(this.statsData)
      if (this.liveData && this.statsData && !Object.hasOwn(this.statsData, 'stats')) {
        console.warn('No Statistics Found: Using LiveData RelatedId mode!')
        return Object.values(this.liveData.devices).map(device => device.device_code)
      } else if (this.statsData && this.statsData.labels.length) {
        return [...new Set([].concat(...Object.keys(this.statsData.stats).map(stat => Object.keys(this.statsData.stats[stat]))))]
      } else {
        return []
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/variables';

.report-page {
  display: flex;
  flex-direction: column;
  align-content: center;
}

.report-heading {
  color: $theme-color-primary-3;
}

.report-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: flex-start;
}

.report-test-class {
  background: $theme-color-primary-3
}

.task-popup {
  z-index: 10000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: $theme-color-background-4;
  border: 1px solid $theme-color-primary-1;
  border-radius: 10px;
  padding: 10px;
}

</style>
