<template>
  <div>
    <div class="chart_holder" v-if="chartData">
      <plotly :data="chartData" :layout="chartLayout" :config="chartConfig" />
    </div>
  </div>
</template>

<script>
import { Plotly } from 'vue-plotly'
import defaultLayout from '@/components/helpers/plotly_style'
import ReportDataHelper from '@/components/helpers/ReportDataHelper'

export default {
  data () {
    return {
      // NOTE: These are auto-populated by the report parent.
      statsData: null,
      liveData: null,
      related_ids: null,
      reportParams: null,
      report: null,
      // End Note

    }
  },

  components: {
    Plotly
  },
  props: {
    layout: {
      type: Object,
      default: () => ({})
    },
    config: {
      type: Object,
      default: () => ({ responsive: true })
    },
    label_field: {
      type: String,
      default: 'label'
    },
    value_field: {
      type: String,
      default: 'value'
    },
    order_field: {
      // With optional + or - prefix to indicate sort order
      type: String,
      default: 'order'
    },
    data_key: {
      type: String,
      default: 'data'
    },
    null_values: {
      // type is any value
      default: 0
    },
    data_source: {
      type: String, // 'live' or 'stats'
      default: 'live'
    },
    is_date_axis: {
      // If true, interpret statsData.labels as date strings like "YYYY/MM/DD"
      // and produce date-based x-axis values
      type: Boolean,
      default: false
    },
    precision: {
      type: Number,
      default: 2 // Default precision to 2 decimal places
    },
    plotType: {
      type: String,
      default: 'bar' // 'bar' or 'scatter', etc
    },
    hideZeroValues: {
      type: Boolean,
      default: false
    },
    value_multiple: {
      type: Number,
      default: 1
    }
  },
  methods: {
    FAILING_getRequiredStats () {  // TODO
      if (this.data_source === 'stats') {
        // Combine value_field, label_field, and order_field into a list, flattening any nested arrays
        return [this.value_field, this.label_field, this.order_field].flat()
      } else {
        return []
      }
    },
    FAILING_getRequiredDeviceProps () {  // TODO
      if (this.data_source === 'live') {
        return [this.value_field, this.label_field, this.order_field].flat()
      } else {
        return []
      }
    }
  },
  computed: {
    chartData() {
      // 1) LIVE DATA => Single trace
      if (this.data_source === 'live') {
        if (!this.liveData || !this.liveData[this.data_key]) {
          return null
        }
        // We assume liveData[this.data_key] is an array or object we can iterate
        const values = Object.values(this.liveData[this.data_key])
        return ReportDataHelper.generateCategoricalPlotLiveData(
          values,
          this.label_field,
          this.value_field,
          this.order_field,
          this.null_values,
          this.plotType,
          this.hideZeroValues,
          this.value_multiple
        )
      } else {
        // 2) STATS DATA => Multiple traces (one per device)
        if (!this.statsData) {
          return null
        }
        // Pass the entire statsData object (which contains {labels, stats, etc.})
        // For multi-trace bar charts, set isDate to this.is_date_axis
        return ReportDataHelper.generateCategoricalPlotStatsData(
          this.statsData,
          this.value_field,       // e.g. 'DEVICE_DAILY_TOTAL_HOURS'
          this.null_values,
          this.is_date_axis,       // parse "YYYY/MM/DD" -> "YYYY-MM-DD"
          this.plotType,           // 'bar' or 'line'
          this.hideZeroValues,
          this.value_multiple
        )
      }
    },
    chartLayout() {
      return {
        ...defaultLayout,
        ...this.layout,
      }
    },
    chartConfig() {
      return this.config
    }
  }
}
</script>

<style scoped>
div.chart_holder {
  width: 100%;
  height: 100%;
  min-height: 400px;
}
</style>
