<template>
  <div>
    <div class="driving-summary-widget">
      <div v-if="statsData && liveData && hasDrivingData" class="driving-summary-container">
        <div v-b-tooltip title="Total Distance travelled in the period.">
          <div class="report-green-label">Total Distance</div>
          <div class="report-white-text">{{numberFormat(getSum('DEVICE_DAILY_TOTAL_KMS'), 0)}} km</div>
        </div>
        <div v-b-tooltip title="Average distance per day (over previous 30 days)">
          <div class="report-green-label">Daily Average Distance</div>
          <div class="report-white-text">{{numberFormat(getMean('DEVICE_DAILY_TOTAL_KMS'))}} km</div>
        </div>
        <div v-b-tooltip title="Total number of running hours for the period">
          <div class="report-green-label">Total Hours</div>
          <div class="report-white-text">{{timeDurationFormat(getSum('DEVICE_DAILY_TOTAL_HOURS'))}}</div>
        </div>
        <div v-b-tooltip title="Average hours per day (over previous 30 days)">
          <div class="report-green-label">Daily Average Hours</div>
          <div class="report-white-text">{{timeDurationFormat(getMean('DEVICE_DAILY_TOTAL_HOURS'))}}</div>
        </div>
        <div v-b-tooltip title="Total fuel consumed during the period.">
          <div class="report-green-label">Fuel Consumption</div>
          <div class="report-white-text">{{numberFormat(getSum('DEVICE_DAILY_TOTAL_FUEL') * 0.01, 2)}} L</div>
        </div>
      </div>
      <div v-if="statsData && liveData && hasDrivingData" class="driving-summary-container">
        <div v-b-tooltip title="MIL Alarm Events, Overheat Alarm Events, etc.">
          <div class="report-green-label">Engine Events</div>
          <div class="report-white-text">{{numberFormat(getSum('DEVICE_DAILY_EVENTS_VEHICLE_ALARM'))}}</div>
        </div>
        <div v-b-tooltip title="Speeding Events.">
          <div class="report-green-label">Speeding Events</div>
          <div class="report-white-text">{{numberFormat(getSum('DEVICE_DAILY_EVENTS_SPEEDING'))}}</div>
        </div>
        <div v-b-tooltip title="Harsh Acceleration Alarm Events, Harsh Turning Alarm Events, High RPM, Harsh Braking, etc.">
          <div class="report-green-label">Harsh Driving Events</div>
          <div class="report-white-text">{{numberFormat(getSum('DEVICE_DAILY_EVENTS_DRIVING'))}}</div>
        </div>
        <div v-b-tooltip title="Fuel efficiency for the reported period.">
          <div class="report-green-label">Fuel Efficiency</div>
          <div class="report-white-text">{{numberFormat((getSum('DEVICE_DAILY_TOTAL_FUEL') / getSum('DEVICE_DAILY_TOTAL_KMS')), 2)}} L/ 100km</div>
        </div>
        <div v-b-tooltip title="Date of the last Vehicle Service Event entered in the system.">
          <div class="report-green-label">Last Service</div>
          <div class="report-white-text">{{ getLastService() }}</div>
        </div>
        <div v-b-tooltip title="Estimated Date for the next service. Today if service is overdue.">
          <div class="report-green-label">Next Service (estimated)</div>
          <div class="report-white-text">{{ getNextService() }}</div>
        </div>
      </div>
      <div v-if="statsData && liveData && !hasDrivingData" class="driving-summary-container">
        <div class="report-white-text">No driving data for this period.</div>
      </div>
    </div>
  </div>
</template>

<script>
import WidgetBase from '@/components/stats/widgets/WidgetBase'
import moment from 'moment-timezone'

export default {
  name: 'WidgetDrivingSummary',
  extends: WidgetBase,
  props: {
    title: String,
    related_id_idx: [Number, String],
    field_labels: Object,
    field_formatting: Object
  },
  data () {
    return {
      // NOTE: These are auto-populated by the report parent.
      statsData: null,
      liveData: null,
      related_ids: null,
      reportParams: null,
      report: null,
      // End Note
      defaultProps: [
        'device_imei',
        'device_name',
        'device_code',
        'registration',
        'icon',
        'hours_tracked',
        'odometer_reading',
        'plant_num',
        'next_service_date',
        'last_service_date'
      ],
      formatters: {
        number: this.numberFormat,
        date: this.dateFormat,
        time: this.timeFormat,
        datetime: this.dateTimeFormat,
        fuel: this.fuelFormat,
        events: this.eventsFormat
      },
      hasDrivingData: false
    }
  },
  async mounted () {
    this.hasDrivingData = true // this.getSum('DEVICE_DAILY_TOTAL_HOURS') > 0
  },
  methods: {
    getRequiredDeviceProps () {
      return [...new Set(this.defaultProps.concat(this.device_props))]
    },
    getFormatter (fieldName) {
      if (this.field_formatting && Object.hasOwn(this.field_formatting, fieldName)) {
        let formatterType = this.field_formatting[fieldName].toLowerCase()
        if (Object.hasOwn(this.formatters, formatterType)) {
          return this.formatters[formatterType]
        } else {
          throw Error('Unknown Field Formatter Type: ' + formatterType)
        }
      } else {
        return this.numberFormat
      }
    },
    timeDurationFormat (value) {
      let alwaysShowHours = true
      let showSeconds = false
      if (isNaN(value)) {
        return value
      }

      // Initially from: https://stackoverflow.com/a/6313008/307363
      const totalInSeconds = parseFloat(value) * 3600
      let hours = Math.floor(totalInSeconds / 3600)
      let minutes = Math.floor((totalInSeconds - (hours * 3600)) / 60)
      let seconds = totalInSeconds - (hours * 3600) - (minutes * 60)

      if (alwaysShowHours || hours > 0){
        if (hours < 10) {
          hours = '0' + hours
        }
        hours = hours + ':'
      } else {
        hours = ''
      }
      if (minutes < 10) {
        minutes = '0' + minutes
      }
      if (showSeconds){
        if (seconds < 10) {
          seconds = '0' + seconds
        }
        seconds = ':' + seconds
      } else {
        seconds = ''
      }
      return hours + minutes + seconds
    },
    numberFormat (value, precision = 0) {
      if (value === null) {
        return 0.00
      } else if (isNaN(value)) {
        return 0
      } else {
        if (value) {
          return parseFloat(value).toFixed(precision)
        } else {
          return 0.00
        }
      }
    },
    dateFormat (value) {
      if (value === 'None' || value === 0 || value == null) {
        return 'None'
      } else {
        return moment(moment.unix(value)).tz(this.statsData.timezone).format('DD-MM-YYYY')
      }
    },
    timeFormat (value) {
      if (value === 'None' || value === 0 || value == null) {
        return 'None'
      } else {
        return moment(moment.unix(value)).tz(this.statsData.timezone).format('HH:mm')
      }
    },
    dateTimeFormat (value) {
      if (value === 'None' || value === 0 || value == null) {
        return 'None'
      } else {
        return moment(moment.unix(value)).tz(this.statsData.timezone).format('DD-MM-YYYY HH:mm')
      }
    },
    fuelFormat (value) {
      if (value === 'None' || value === 0) {
        return '0.00L'
      } else {
        return `${value * 0.01}L`
      }
    },
    eventsFormat (value) {
      if (value === 'None' || value === 0 || value === null) {
        return 'None'
      } else {
        return `${value.toFixed(0)} Events`
      }
    },
    getStatLabel (stat) {
      if (this.field_labels && Object.hasOwn(this.field_labels, stat)) {
        return this.field_labels[stat]
      } else {
        return stat
      }
    },
    clickRelatedId (link) {
      let routeData = this.$router.resolve(link)
      window.open(routeData.href, '_blank')
    },
    getValues (statName) {
      let values = []
      let idx = this.related_ids[this.related_id_idx]
      if (!this.statsData['stats'][statName]) {
        console.error('Stat not found: ', statName)
      }
      let specificStatsData = this.statsData['stats'][statName]
      for (const dateKey in specificStatsData[idx]) {
        values.push(specificStatsData[idx][dateKey])
      }
      return values
    },
    getSum (statName) {
      let statValues = this.getValues(statName)
      if (statValues.length === 0) {
        return 0
      }
      return statValues.reduce((sum, x) => sum + x)
    },
    getMean (statName) {
      let statValues = this.getValues(statName)
      if (statValues.length === 0) {
        return 0
      }
      return statValues.reduce((sum, x) => sum + x) / statValues.length
    },
    getLastService () {
      if (this.deviceData && this.deviceData['last_service_date']) {
        return this.dateFormat(this.deviceData['last_service_date'])
      } else {
        return 'Unknown'
      }
    },
    getNextService () {
      if (this.deviceData && this.deviceData['next_service_date']) {
        return this.dateFormat(this.deviceData['next_service_date'])
      } else {
        return 'Unknown'
      }
    }
  },
  computed: {
    deviceData () {
      if (this.liveData && this.related_ids) {
        // let relatedIds = [...new Set([].concat(...Object.keys(this.statsData.stats).map(stat => Object.keys(this.statsData.stats[stat]))))]
        let imei = Object.keys(this.liveData.devices).find(imei => this.liveData.devices[imei].device_code === this.related_ids[this.related_id_idx])
        if (imei) {
          return this.liveData.devices[imei]
        } else {
          return null
        }
      } else {
        return null
      }
    },
    relatedLinks () {
      let links = {}
      if (this.liveData) {
        for (let device in this.liveData.devices) {
          links[this.liveData.devices[device].device_code] = `/device/${this.liveData.devices[device].device_imei}`
        }
      }
      return links
    },
  }
}
</script>

<style scoped lang="scss">
@import '@/variables';

  .driving-summary-widget {
    //margin-bottom: 2em;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .driving-summary-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: center;
  }
  .driving-summary-container div{
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0px 5px;
  }

  .green {
    margin-top: 0;
    font-size: 1.2em;
  }

  .vehicle-summary-text {
    color: white;
    font-size: 1.1em;
    padding-left: 1em;
  }

@media print {
  .table-widget {
    max-width: 1000px;
  }
  .vehicle-summary-text {
    color: black;
  }
}

</style>
