<template>
<div class="widget-live-metric" ref="base">
  <div class="number-overlay" v-if="liveData" :style="{'font-size': numberFontSize}">
    <div>{{ metricValue }}</div>
    <div class="metric-suffix">{{metrixSuffixText}}</div>
  </div>
  <b-tooltip :target="$refs.base" triggers="hover" v-if="$refs.base">
    <ul>
      <li v-for="(device, idx) of tooltipObjects" v-bind:key="idx">
<!--        TODO - This will break when we start using this widget for something other than devices! -->
        {{device.device_name}}
      </li>
    </ul>
  </b-tooltip>
</div>
</template>

<script>
/***
 *    Live Metric Widget
 * Displays a simple single number metric. Supposed to look almost identical to a TrendLineGraph widget,
 * but for metrics without any trending (i.e. count of devices)
 ***/
import WidgetBase from '@/components/stats/widgets/WidgetBase'
import ReportHelper from '@/components/helpers/ReportHelper'

export default {
  name: 'WidgetLiveMetric',
  components: { },
  extends: WidgetBase,
  props: {
    statistics: Array,
    aggregration_mode: String, // How to aggregate stats
    metric_suffix: String,
    device_props: Array,
    aggregation_compare_value: Object // Value to compare to when using 'COUNT-IF'
  },
  data () {
    return {
      // NOTE: These are auto-populated by the report parent.
      statsData: null,
      liveData: null,
      related_ids: null,
      reportParams: null,
      report: null,
      // End Note
      modeLookup: {
        SUM: this.sumProp,
        COUNT: this.countProp,
        'COUNT-TRUE': this.countIfTrue,
        'COUNT-NOT-NULL': this.countNotNull,
        'COUNT-DISTINCT': this.countDistinct,
        'COUNT-IF': this.countIfEqual
      }
    }
  },
  methods: {
    numberFormat (value) {
      if (value) {
        return value.toFixed(2)
      } else {
        return 0
      }
    }
  },
  computed: {
    numberFontSize () {
      return 6 - (String(this.metricValue).length / 1.5) + 'em'
    },
    metricValue () {
      let devices = Object.keys(this.liveData.devices).map(deviceImei => this.liveData.devices[deviceImei])
      let mode = this.aggregration_mode.toUpperCase()
      return ReportHelper.getArrayOperatorResult(mode, devices, this.device_props[0], this.aggregation_compare_value)
    },
    tooltipObjects () {
      let devices = Object.keys(this.liveData.devices).map(deviceImei => this.liveData.devices[deviceImei])
      let mode = this.aggregration_mode.toUpperCase()
      return ReportHelper.getArrayOperatorArray(mode, devices, this.device_props[0], this.aggregation_compare_value)
    },
    lastMetricChange () {
      if (this.chartData.datasets[0].data.length > 1) {
        let vals = this.chartData.datasets[0].data.slice(-2)
        return vals[1] - vals[0]
      } else {
        return 0
      }
    },
    metrixSuffixText () {
      if (this.metric_suffix) {
        return this.metric_suffix
      } else {
        return ''
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/variables';

.widget-live-metric {
  position: relative;
  width: 150px;
  height: 150px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.number-overlay {
  font-weight: bold;
  position: absolute;
  display: flex;
  flex-flow: column wrap;
  align-content: center;
  justify-content: center;
  //transform: translate(-50%, -50%);
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);
  margin-bottom: 0;
  height: 100%;
  top: -16px;
}

.metric-suffix {
  position: absolute;
  bottom: -18px;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 32px;
}

.trend-indicator{
  position: absolute;
  top: 17px;
  right: 0px;
  font-size: 2em;
}

.trend-down {
  color: #900000;
  -webkit-text-stroke: 1px #e50000;
}

.trend-up {
  color: #2e72ff;
  -webkit-text-stroke: 1px #022673;
}

.trend-same {
  font-size: 0.8em;
}

@media print {
  .number-overlay {
    -webkit-text-stroke: 2px #595959;
  }
}

</style>
