<template>
<div class="bar-graph-widget">
  Chart Data: {{ chartData }}
  <BarGraph v-if="statsData" :chart-data="chartData" :options="options" responsive></BarGraph>
</div>
</template>

<script>
import BarGraph from '@/components/stats/charts/BarGraph.vue'
import ReportHelper from '@/components/helpers/ReportHelper'
import WidgetBarGraph from '@/components/stats/widgets/WidgetBarGraph.vue'

export default {
  name: 'WidgetFloatingBarGraph',
  components: { BarGraph },
  extends: WidgetBarGraph,
  props: {
    title: String,
    startStatistics: String,
    endStatistics: String,
    chartOptions: Object,
    aggregration_mode: String,
    field_labels: Object,
    live_data_labels: String, // Ignore Stats labels and one of the live fields instead
    stack_by_device_prop: String, // Create a stacked graph using related_id to create different datasets (stats only)
    separate_y_axes: Boolean,
    field_suffixes: Object, // Suffix to append to the field (for tooltips)
  },
  computed: {
    chartData () {
      let data = {
        labels: [],
        datasets: []
      }
      if (this.statsData) {
        let colorIdx = 0
        let fieldLabel = 'Time'
        if (this.startStatistics && Object.hasOwn(this.statsData, 'stats')) {
          if (this.device_props) {
            console.warn('Warning: Bar Graph is using both LiveData AND Statistical Data. It might be ugly...')
          }
          data.labels = data.labels.concat(this.statsData.labels)
          for (let relatedId of Object.keys(this.statsData.stats[this.startStatistics])) {
            data.datasets.push({
              fieldId: fieldLabel,
              label: fieldLabel,
              // Zip start and end statistics together
              data: this.statsData.labels.map((label, idx) => {
                return [this.statsData.stats[this.startStatistics][relatedId][label],
                  this.statsData.stats[this.endStatistics][relatedId][label]]
              }),
              backgroundColor: ReportHelper.getReportColor(colorIdx),
              borderColor: ReportHelper.getReportColor(colorIdx),
              fill: false
            })
          }
          colorIdx++
        }
      }
      console.log('BAR GRAPH DATA: ', data)
      return data
    }
  }
}
</script>

<style scoped>
.bar-graph-widget {
  position: relative;
  width: 100%;
  height: 100%;
  > div {
    position: relative;
    height: 100%;
  }
}
</style>
