<template>
<div class="line-graph-widget">
  <LineGraph v-if="statsData" :chart-data="chartData" :options="options"></LineGraph>
</div>
</template>

<script>
import WidgetBase from '@/components/stats/widgets/WidgetBase'
import LineGraph from '@/components/stats/charts/LineGraph'
import ReportHelper from '@/components/helpers/ReportHelper'

export default {
  name: 'WidgetLineGraph',
  components: { LineGraph },
  extends: WidgetBase,
  props: {
    title: String,
    statistics: Array,
    chart_options: Object,
    aggregration_mode: String,
    field_labels: Object,
    field_suffixes: Object // Suffix to append to the field (for tooltips)
  },
  data () {
    return {
      // NOTE: These are auto-populated by the report parent.
      statsData: null,
      liveData: null,
      related_ids: null,
      reportParams: null,
      report: null,
      // End Note
      defaultOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            display: true,
            ticks: {
              suggestedMin: 0
            }
          }]
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              console.log(tooltipItem, data)
              let dataset = data.datasets[tooltipItem.datasetIndex]
              return `${dataset.label}: ${tooltipItem.yLabel.toFixed(1)} ${this.getFieldSuffix(dataset)}`
            }
          }
        }
      },
      aggregation_methods: {
        'SUM': (x, y) => x + y,
        'MAX': (x, y) => Math.max(x, y),
        'MIN': (x, y) => Math.min(x, y)
      }
    }
  },
  methods: {
    getFieldSuffix (dataSet) {
      console.log(dataSet)
      if (this.field_suffixes && Object.hasOwn(dataSet, 'fieldId') && Object.hasOwn(this.field_suffixes, dataSet.fieldId)) {
        return this.field_suffixes[dataSet.fieldId]
      } else {
        return ''
      }
    },
    getStatLabel (stat) {
      if (this.field_labels && Object.hasOwn(this.field_labels, stat)) {
        return this.field_labels[stat]
      } else {
        return stat
      }
    },
    numberFormat (value) {
      if (value) {
        return value.toFixed(2)
      } else {
        return 0
      }
    },
    getDevicename (deviceCode) {
      if (this.liveData && Object.hasOwn(this.liveData.relatedIdLookup, deviceCode)) {
        return this.liveData.relatedIdLookup[deviceCode]
      } else {
        return deviceCode
      }
    }
  },
  computed: {
    options () {
      return { ...this.defaultOptions, ...this.chart_options }
    },
    chartData () {
      let data = {
        labels: this.statsData.labels,
        datasets: []
      }
      // Add Datasets for liveData.
      if (this.device_props && this.liveData) {
      }

      // Add Datasets for Statistical Data
      if (this.statistics && Object.hasOwn(this.statsData, 'stats')) {
        // let data = {
        //   labels: this.statsData.labels,
        //   datasets: []
        // }
        let colorIdx = 0

        for (let stat of this.statistics) {
          if (Object.hasOwn(this.statsData.stats, stat)) {
            if (!this.aggregration_mode) {
              for (let relatedId of Object.keys(this.statsData.stats[stat])) {
                data.datasets.push({
                  fieldId: stat,
                  label: this.getDevicename(relatedId),
                  data: Object.keys(this.statsData.stats[stat][relatedId]).map(date => this.statsData.stats[stat][relatedId][date]),
                  backgroundColor: ReportHelper.getReportColor(colorIdx),
                  borderColor: ReportHelper.getReportColor(colorIdx),
                  fill: false
                })
                colorIdx++
              }
            } else {
              if (Object.hasOwn(this.aggregation_methods, this.aggregration_mode)) {
                console.log(this.statsData.labels.map(date =>
                  Object.keys(this.statsData.stats[stat]).map(deviceCode =>
                    this.statsData.stats[stat][deviceCode][date])))
                data.datasets.push({
                  fieldId: stat,
                  label: this.getStatLabel(stat),
                  data: this.statsData.labels.map(date =>
                    Object.keys(this.statsData.stats[stat]).map(deviceCode =>
                      this.statsData.stats[stat][deviceCode][date]).reduce(this.aggregation_methods[this.aggregration_mode])),
                  backgroundColor: ReportHelper.getReportColor(colorIdx),
                  borderColor: ReportHelper.getReportColor(colorIdx),
                  fill: false
                })
                colorIdx++
              } else {
                throw Error('Unknown Aggregation Method: ' + this.aggregration_mode)
              }
            }
          }
        }
        console.log('WIDGET AREA GRAPH DATA: ', data)
      }
      return data
    }
  }
}
</script>

<style scoped>
.line-graph-widget {
  position: relative;
  width: 100%;
  height: 100%;
  > div {
    position: relative;
    height: 100%;
  }
}
</style>
