<template>
<div class="widget-list" v-if="statsData" ref="base" :id="'base' + _uid">
  <b-tooltip :target="'base' + _uid" triggers="hover">
    {{tooltipText}}
  </b-tooltip>
  <div class="background-graph">
    <ol v-if="listEntries.length">
      <li v-for="(relatedId, idx) in listEntries" v-bind:key="idx">{{relatedId}}</li>
    </ol>
    <div v-else>
      None
    </div>
  </div>
</div>
</template>

<script>
/***
 *    List Widget
 * Displays a list of related Ids (devices) ordered by the provided statistics and/or Live Data. Aggregation modes can
 * be selected as either sum or count.
 ***/
import WidgetBase from '@/components/stats/widgets/WidgetBase'
import ReportHelper from '@/components/helpers/ReportHelper'

export default {
  name: 'WidgetList',
  components: { },
  extends: WidgetBase,
  props: {
    title: String,
    statistics: Array,
    aggregration_mode: String, // How to aggregate stats (SUM or COUNT, default to SUM)
    operator: String,
    limit: Number, // Limit the number of results in the table.
    metric_suffix: String,
    sort_by: String, // ASC or DESC (default to DESC)
    hide_zeros: Boolean,
    description: String
  },
  data () {
    return {
      // NOTE: These are auto-populated by the report parent.
      statsData: null,
      liveData: null,
      related_ids: null,
      reportParams: null,
      report: null,
      // End Note
      defaultProps: ['device_imei', 'device_name', 'device_code']
    }
  },
  methods: {
    getRequiredDeviceProps () {
      return [...new Set(this.defaultProps.concat(this.device_props))]
    },
    numberFormat (value) {
      if (value) {
        return value.toFixed(2)
      } else {
        return 0
      }
    },
    getDevicename (deviceCode) {
      if (Object.hasOwn(this.liveData.relatedIdLookup, deviceCode)) {
        return this.liveData.relatedIdLookup[deviceCode]
      } else {
        return deviceCode
      }
    }
  },
  computed: {
    mode () {
      if (this.aggregration_mode) {
        return this.aggregration_mode
      } else {
        return 'SUM'
      }
    },
    metricSuffix () {
      if (this.metric_suffix) {
        return this.metric_suffix
      } else {
        return ''
      }
    },
    listEntries () {
      let relatedIds = [...new Set([].concat(...this.statistics.map(stat => Object.keys(this.statsData.stats[stat]))))]
      // Setup an object to hold our sorting values
      let scores = relatedIds.map(relatedId => { return { id: relatedId, score: 0 } })

      if (this.device_props && this.device_props.length) {
        this.device_props.forEach(deviceProp => {
          scores.forEach(relatedIdScore => {
            if (this.mode === 'COUNT') {
              relatedIdScore.score += 1
            } else {
              relatedIdScore.score += this.liveData.devices[relatedIdScore.id][deviceProp]
            }
          })
        })
      }
      this.statistics.forEach(statisticId => {
        scores = scores.map(relatedIdScore => {
          // Create an array of the data for this related ID by aggregating all the dates
          let values = Object.keys(this.statsData.stats[statisticId][relatedIdScore.id]).map(date =>
            parseFloat(this.statsData.stats[statisticId][relatedIdScore.id][date])
          )
          if (this.mode === 'COUNT') {
            relatedIdScore.score += values.length
          } else {
            relatedIdScore.score += ReportHelper.sumArray(values)
          }
          return {
            'id': relatedIdScore.id,
            'score': relatedIdScore.score
          }
        })
      })
      // Sort Results
      scores.sort((a, b) => {
        if (this.sort_by === 'ASC') {
          return a.score - b.score
        } else {
          return b.score - a.score
        }
      })
      if (this.limit) {
        scores = scores.slice(0, this.limit)
      }
      if (this.hide_zeros) {
        scores = scores.filter(scoreObj => scoreObj.score > 0)
      }
      scores = scores.map(scoreObj => `${this.getDevicename(scoreObj.id)} - ${scoreObj.score.toFixed(0)} ${this.metricSuffix}`)
      return scores
    },
    tooltipText () {
      return this.description
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/variables';

ol {
  padding-left: 1em;
}

li::marker {
  color: white;
}

@media print {
  li::marker {
    color: #3d3d3d;
  }

  li {
    color: black;
  }

}

</style>
