<template>
<div class="bar-graph-widget">
  <BarGraph v-if="statsData" :chart-data="chartData" :options="options" responsive></BarGraph>
</div>
</template>

<script>
import WidgetBase from '@/components/stats/widgets/WidgetBase'
import BarGraph from '@/components/stats/charts/BarGraph.vue'
import ReportHelper from '@/components/helpers/ReportHelper'

export default {
  name: 'WidgetBarGraph',
  components: { BarGraph },
  extends: WidgetBase,
  props: {
    title: String,
    statistics: Array,
    chartOptions: Object,
    aggregration_mode: String,
    field_labels: Object,
    live_data_labels: String, // Ignore Stats labels and one of the live fields instead
    stack_by_device_prop: String, // Create a stacked graph using related_id to create different datasets (stats only)
    separate_y_axes: Boolean,
    field_suffixes: Object, // Suffix to append to the field (for tooltips)
    preprocessMap: Object, // Map of preprocessors to apply to stats
    // Whether the legend should be displayed, default true
    showLegend: {
      type: Boolean,
      default: true
    },
    // Offset the colour choices by this amount
    colourOffset: {
      type: Number,
      default: 0
    },
  },
  data () {
    return {
      // NOTE: These are auto-populated by the report parent.
      statsData: null,
      liveData: null,
      related_ids: null,
      reportParams: null,
      report: null,
      // End Note
      preprocessors: {
        // Convert from timestamp to seconds-since-midnight
        TIME_OF_DAY: (x, timezone) => {
          let date = new Date(x * 1000)
          return date.getHours() * 3600 + date.getMinutes() * 60 + date.getSeconds()
        }
      },
      // Any stats which require pre-processing should be handled here (e.g. Fuel needs to be /100)
      statFormatters: {
        DEVICE_DAILY_TOTAL_FUEL: (x) => x * 0.01,
        DEVICE_DAILY_START_TIME: (x) => {
          let hours = Math.floor(x / 3600)
          let minutes = Math.floor((x % 3600) / 60)
          let seconds = x % 60
          return `${hours}:${minutes}:${seconds}`
        },
        DEVICE_DAILY_END_TIME: (x) => {
          let hours = Math.floor(x / 3600)
          let minutes = Math.floor((x % 3600) / 60)
          let seconds = x % 60
          return `${hours}:${minutes}:${seconds}`
        }
      },
      defaultOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              display: false,
              suggestedMin: 0
            }
          }],
          xAxes: [{}]
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              let dataset = data.datasets[tooltipItem.datasetIndex]
              return `${dataset.label}: ${tooltipItem.yLabel.toFixed(1)} ${this.getFieldSuffix(dataset)}`
            }
          }
        }
      },
      aggregation_methods: {
        'SUM': (x, y) => x + y,
        'MAX': (x, y) => Math.max(x, y),
        'MIN': (x, y) => Math.min(x, y),
        'COUNT': (x, y) => x + 1
      }
    }
  },
  methods: {
    getFieldSuffix (dataSet) {
      if (this.field_suffixes && Object.hasOwn(dataSet, 'fieldId') && Object.hasOwn(this.field_suffixes, dataSet.fieldId)) {
        return this.field_suffixes[dataSet.fieldId]
      } else {
        return ''
      }
    },
    getStatLabel (stat) {
      if (this.field_labels && Object.hasOwn(this.field_labels, stat)) {
        return this.field_labels[stat]
      } else {
        return stat
      }
    },
    getRelatedIdLabel (id) {
      if (this.liveData && Object.hasOwn(this.liveData.relatedIdLookup, id)) {
        return this.liveData.relatedIdLookup[id]
      } else {
        return id
      }
    },
    groupLiveDataByProp (devices, prop, labels) {
      let data = {}
      labels.forEach(labelVal => {
        data[labelVal] = Object.values(devices).filter(device => this.formatPropForIndex(device[prop]) === labelVal)
      })
      return data
    },
    getPropAxesName (propName) {
      // Note: This behavior should cause the datasets to use the default axis if axes separation is off.
      if (this.separate_y_axes) {
        return `y-${propName}`
      } else {
        return 0
      }
    },
    getUniqueDeviceProps (propName) {
      return [...new Set(
        [].concat(...Object.keys(this.liveData.devices).map(deviceId => {
          return this.formatPropForIndex(this.liveData.devices[deviceId][propName])
        }))
      )]
    },
    /**
     * This avoids having 'null' as a label
     * @param value
     * @return {string|*}
     */
    formatPropForIndex (value) {
      return value === null ? 'None' : value
    },
    formatStatData(statName, value) {
      if (this.statFormatters && Object.hasOwn(this.statFormatters, statName)) {
        return this.statFormatters[statName](value)
      } else {
        return value
      }
    },
    preprocessStat(preprocessorName, value) {
      if (this.preprocessMap && Object.hasOwn(this.preprocessors, preprocessorName)) {
        return this.preprocessors[preprocessorName](value)
      } else {
        return value
      }
    }
  },
  computed: {
    options () {
      let chartOptions = { ...this.defaultOptions, ...this.chartOptions }
      // Create yAxes for Each Device Prop
      if (this.separate_y_axes) {
        let additionalAxes = this.device_props.map((propName) => {
          return {
            id: this.getPropAxesName(propName),
            label: this.getStatLabel(propName),
            ticks: {
              display: false,
              suggestedMin: 0
            }
          }
        })
        chartOptions.scales.yAxes = chartOptions.scales.yAxes.concat(...additionalAxes)
      }
      if (this.stack_by_device_prop) {
        chartOptions.scales.yAxes[0].stacked = true
        chartOptions.scales.xAxes[0].stacked = true
      }
      if (this.showLegend) {
        chartOptions.legend = {
          display: true
        }
      } else {
        chartOptions.legend = {
          display: false
        }
      }
      return chartOptions
    },
    chartData () {
      let data = {
        labels: [],
        datasets: []
      }
      let aggregationMode = 'SUM'
      if (this.aggregration_mode) {
        aggregationMode = this.aggregration_mode
      }
      let colorIdx = this.colourOffset

      if (this.liveData && this.device_props) {
        // If we've been instructed to use one or more live fields as labels then convert them
        let labelField = 'device_name'
        if (this.live_data_labels) {
          labelField = this.live_data_labels
        }
        // For each liveDataField we need ot find the unique values, so we grab all the values for each prop and concat
        // them into a single array, then use Set to ge the unique values.
        data.labels = this.getUniqueDeviceProps(labelField)
        let groupedDevices = this.groupLiveDataByProp(this.liveData.devices, labelField, data.labels)
        for (let deviceProp of this.device_props) {
          // Skip the Device Prop we're using as axis labels for the graph
          if (deviceProp === labelField) {
            continue
          }
          // Chart Data is taking the devices, sorted by the label field, mapping to the deviceProp we're up to and then
          // applying the aggregation function to reduce it to a scalar value for each label.
          data.datasets.push({
            fieldId: deviceProp,
            yAxisID: this.getPropAxesName(deviceProp),
            label: this.getStatLabel(deviceProp),
            data: data.labels.map(labelVal =>
              groupedDevices[labelVal].map(device => device[deviceProp]).reduce(this.aggregation_methods[aggregationMode], 0)),
            backgroundColor: ReportHelper.getReportColor(colorIdx),
            borderColor: ReportHelper.getReportColor(colorIdx),
            fill: false
          })
          colorIdx++
        }
      }

      if (this.statistics && Object.hasOwn(this.statsData, 'stats')) {
        if (this.device_props) {
          console.warn('Warning: Bar Graph is using both LiveData AND Statistical Data. It might be ugly...')
        }
        data.labels = data.labels.concat(this.statsData.labels)
        for (let stat of this.statistics) {
          let preprocessorName = 'NONE'
          if (this.preprocessMap && Object.hasOwn(this.preprocessMap, stat)) {
            preprocessorName = this.preprocessMap[stat]
          }
          if (this.stack_by_device_prop) {
            let devicesByProp = this.getUniqueDeviceProps(this.stack_by_device_prop)
          }

          if (Object.hasOwn(this.statsData.stats, stat)) {
            // If we're not aggregating, then create a new dataset for each RelatedId
            if (aggregationMode === 'NONE') {
              for (let relatedId of Object.keys(this.statsData.stats[stat])) {
                data.datasets.push({
                  fieldId: stat,
                  label: this.getRelatedIdLabel(relatedId),
                  data: Object.keys(this.statsData.stats[stat][relatedId]).map(
                    date => this.formatStatData(stat, this.preprocessStat(preprocessorName, this.statsData.stats[stat][relatedId][date]))),
                  backgroundColor: ReportHelper.getReportColor(colorIdx),
                  borderColor: ReportHelper.getReportColor(colorIdx),
                  fill: false
                })
                colorIdx++
              }
            } else {
              if (Object.hasOwn(this.aggregation_methods, aggregationMode)) {
                // console.log(this.statsData.labels.map(date =>
                //   Object.keys(this.statsData.stats[stat]).map(deviceCode =>
                //     this.statsData.stats[stat][deviceCode][date])))
                data.datasets.push({
                  fieldId: stat,
                  label: this.getStatLabel(stat),
                  data: this.statsData.labels.map(date =>
                    Object.keys(this.statsData.stats[stat]).map(deviceCode =>
                      this.formatStatData(stat, this.preprocessStat(preprocessorName, this.statsData.stats[stat][deviceCode][date]))).reduce(
                      this.aggregation_methods[aggregationMode], 0)),
                  backgroundColor: ReportHelper.getReportColor(colorIdx),
                  borderColor: ReportHelper.getReportColor(colorIdx),
                  fill: false
                })
                colorIdx++
              } else {
                throw Error('Unknown Aggregation Method: ' + this.aggregration_mode)
              }
            }
          }
        }
      }
      // console.log('BAR GRAPH DATA: ', data)
      return data
    }
  }
}
</script>

<style scoped>
.bar-graph-widget {
  position: relative;
  width: 100%;
  height: 100%;
  > div {
    position: relative;
    height: 100%;
  }
}
</style>
