<template>
  <div>
    <div class="chart_holder" v-if="chartData">
      <plotly :data="chartData" :layout="chartLayout" :config="chartConfig" />
    </div>
  </div>
</template>

<script>
import BarChartPlotly from './BarChartPlotly.vue'
import defaultLayout from '@/components/helpers/plotly_style'
import ReportDataHelper from '@/components/helpers/ReportDataHelper'

export default {
  /**
   * Inherit from BarChartPlotly
   */
  extends: BarChartPlotly,

  /**
   * New or overridden props for the histogram
   */
  props: {
    grouping_field: {
      type: String,
      default: 'timestamp' // or whichever field you want to bin
    },
    group_type: {
      type: String,
      default: 'date' // 'numeric' or 'date'
    },
    group_param: {
      // For date-based bins, this can be 'minute','hour','day','week','month','year'
      // For numeric bins, this is the bin size (number) as a string
      type: String,
      default: 'month'
    },
    data_source: {
      type: String, // 'live' or 'stats'
      default: 'live'
    },
    field_name: {
      type: String,
      default: 'value' // Default field to extract from data
    },
    null_value: {
      default: null
    },
    precision: {
      type: Number,
      default: 2 // Default precision to 2 decimal places
    },
    plotType: {
      type: String,
      default: 'bar'
    }
  },

  computed: {
    /**
     * Overriding chartData to generate histogram data instead of categorical data
     */
    chartData() {
      let data;
      if (this.data_source === 'live') {
        const rawArray = Object.values(this.liveData || [])
        data = ReportDataHelper.generateHistogramPlotLiveData(
          rawArray,
          this.field_name,
          this.group_type,
          this.group_param,
          this.null_value,
          this.precision,
          this.plotType
        )
      } else {
        data = ReportDataHelper.generateHistogramPlotStatsData(
          this.statsData,
          this.field_name,
          this.group_type,
          this.group_param,
          this.null_value,
          this.precision,
          this.plotType
        )
      }

      // Apply rounding to y-values for all traces
      return data.map(trace => ({
        ...trace,
        y: trace.y.map(val => Number.isFinite(val) ? Number(val.toFixed(this.precision)) : val)
      }))
    },

    /**
     * Optionally override the layout so it reflects a histogram
     */
    chartLayout() {
      const layout2 = {
        ...defaultLayout,
        // Unfortunately, mutations occur unless we clone the axis
        yaxis: {
          title: 'Count',
          ...defaultLayout.yaxis,
        },
        xaxis: {
          ...defaultLayout.xaxis,
        },
        ...this.layout,
      }

      layout2.yaxis.tickmode = 'linear'
      layout2.yaxis.dtick = 1
      layout2.yaxis.rangemode = 'tozero'

      // If we are dealing with dates, set x-axis type and format
      if (this.group_type === 'date') {
        layout2.xaxis.type = 'date'
        // Basic mapping for demonstration
        // Adjust as you see fit for your labeling preference
        let tickFormat
        switch (this.group_param) {
          case 'minute':
            tickFormat = '%Y-%m-%d %H:%M'
            break
          case 'hour':   tickFormat = '%Y-%m-%d %H:00'; break
          case 'day':    tickFormat = '%Y-%m-%d'; break
          case 'week':   tickFormat = 'Week of %Y-%m-%d'; break
          case 'month':  tickFormat = '%B, %Y'; break
          case 'year':   tickFormat = '%Y'; break
          default:
            // fallback
            tickFormat = 'YYYY-MM-DD'
        }
        // Use tickformat so the axis labels reflect the grouping
        layout2.xaxis.tickformat = tickFormat

        // If you want to block zooming beyond the grouping level,
        // you could experiment with dtick in milliseconds for each grouping:
        // e.g., 1 minute = 60000, 1 hour = 3600000, etc.
        // layout.xaxis.tickmode = 'auto'
        // layout.xaxis.dtick = ...
        // layout.xaxis.rangeslider = { visible: false } // optionally disable slider
      }

      // These fixes help if there is just one bar in the histogram
      layout2.bargap = 0.75 // 0 <= bargap <= 1
      layout2.bargroupgap = 0.1

      // Merge with default layout, if not already set. Must be done as a new object to avoid mutation
      return { ...defaultLayout, ...layout2 }
    }
  }
}
</script>

<style scoped>
div.chart_holder {
  width: 100%;
  height: 100%;
  min-height: 400px;
}
</style>
