var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.childReportData
    ? _c(
        "div",
        [
          _c("ReportComponent", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.statsData && _vm.liveData,
                expression: "statsData && liveData"
              }
            ],
            ref: "childReport",
            attrs: {
              report: _vm.childReportData,
              reportParams: _vm.reportParams,
              relatedIds: _vm.related_ids,
              liveData: _vm.liveData,
              statsData: _vm.statsData
            },
            on: {
              required_stats: _vm.onStats,
              required_device_props: _vm.onProps
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }