var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "base", staticClass: "widget-trend-line-graph" },
    [
      _c(
        "div",
        { staticClass: "background-graph" },
        [
          _vm.statsData
            ? _c("LineGraph", {
                attrs: { "chart-data": _vm.chartData, options: _vm.options }
              })
            : _vm._e()
        ],
        1
      ),
      _vm.$refs.base && _vm.tooltipText
        ? _c(
            "b-tooltip",
            { attrs: { target: _vm.$refs.base, triggers: "hover" } },
            [_vm._v(" " + _vm._s(_vm.tooltipText) + " ")]
          )
        : _vm._e(),
      _vm.statsData
        ? _c(
            "div",
            {
              staticClass: "number-overlay",
              style: { "font-size": _vm.numberFontSize }
            },
            [
              _c("div", [
                _vm._v(_vm._s(_vm.foregroundNumber) + " "),
                _vm.statsData
                  ? _c("div", { staticClass: "trend-indicator" }, [
                      _vm.lastMetricChange < 0
                        ? _c("i", {
                            directives: [
                              { name: "b-tooltip", rawName: "v-b-tooltip" }
                            ],
                            staticClass: "fa fa-caret-down trend-down",
                            attrs: { title: _vm.metricTooltipText }
                          })
                        : _vm._e(),
                      _vm.lastMetricChange === 0
                        ? _c("i", {
                            directives: [
                              { name: "b-tooltip", rawName: "v-b-tooltip" }
                            ],
                            staticClass: "fa fa-minus trend-same",
                            attrs: { title: _vm.metricTooltipText }
                          })
                        : _vm._e(),
                      _vm.lastMetricChange > 0
                        ? _c("i", {
                            directives: [
                              { name: "b-tooltip", rawName: "v-b-tooltip" }
                            ],
                            staticClass: "fa fa-caret-up trend-up",
                            attrs: { title: _vm.metricTooltipText }
                          })
                        : _vm._e()
                    ])
                  : _vm._e()
              ]),
              _c("div", { staticClass: "metric-suffix" }, [
                _vm._v(_vm._s(_vm.metrixSuffixText))
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }