var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table-widget" },
    [
      !_vm.statsNonZero && !_vm.device_props
        ? _c("div", [_vm._m(0)])
        : _vm._e(),
      _vm.liveData && _vm.statsData
        ? _c("b-table", {
            attrs: {
              items: _vm.tableRows,
              fields: _vm.tableFields,
              bordered: "",
              hover: "",
              striped: "",
              small: "",
              "tbody-tr-class": _vm.rowStyleClass,
              "thead-tr-class": "report-widget-table-header",
              "thead-class": "report-widget-table-header",
              "sort-by": _vm.sort_by,
              "sort-desc": _vm.sort_desc
            },
            scopedSlots: _vm._u(
              [
                _vm.tableHeaders
                  ? {
                      key: "thead-top",
                      fn: function(statsData) {
                        return [
                          _c(
                            "b-tr",
                            _vm._l(_vm.tableHeaders, function(header, idx) {
                              return _c(
                                "b-th",
                                {
                                  key: idx,
                                  attrs: { colspan: header.colspan }
                                },
                                [_vm._v(" " + _vm._s(header.title) + " ")]
                              )
                            }),
                            1
                          )
                        ]
                      }
                    }
                  : null,
                {
                  key: "table-colgroup",
                  fn: function(scope) {
                    return _vm._l(scope.fields, function(field) {
                      return _c("col", {
                        key: field.key,
                        style: { width: field.width ? field.width : "100%" }
                      })
                    })
                  }
                },
                {
                  key: "cell(related_id)",
                  fn: function(row) {
                    return [
                      Object.hasOwn(_vm.relatedLinks, row.item.related_id)
                        ? _c(
                            "div",
                            {
                              directives: [
                                { name: "b-tooltip", rawName: "v-b-tooltip" }
                              ],
                              staticClass: "fake-link",
                              attrs: {
                                title: "You can view this device's page."
                              },
                              on: {
                                click: function($event) {
                                  return _vm.clickRelatedId(
                                    _vm.relatedLinks[row.item.related_id]
                                  )
                                }
                              }
                            },
                            [
                              _c("DeviceAvatar", {
                                attrs: {
                                  device: _vm.devicesByCode[row.item.related_id]
                                }
                              })
                            ],
                            1
                          )
                        : _c("div", [_vm._v(_vm._s(row.item.related_id))])
                    ]
                  }
                },
                {
                  key: "cell()",
                  fn: function(ref) {
                    var item = ref.item
                    var value = ref.value
                    var field = ref.field
                    var formatter = ref.formatter
                    return [
                      _vm.getFormatterType(field.key) === "event"
                        ? _c(
                            "div",
                            [
                              value !== ""
                                ? _c("EventAvatar", {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip"
                                      }
                                    ],
                                    attrs: {
                                      event: value,
                                      device_imei: _vm.getDeviceIMEI(
                                        value.device_id
                                      ),
                                      enable_link: "",
                                      title: "Open Event in New Tab"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        : _c("div", [_vm._v(" " + _vm._s(value) + " ")])
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        : _vm._e(),
      _vm.statsData && !_vm.statsData.stats && !_vm.device_props
        ? _c("div", [_c("h3", [_vm._v("No Results")])])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", { staticClass: "no-data" }, [
      _c("i", { staticClass: "fa fa-exclamation-triangle mr-2" }),
      _vm._v("No data recorded in time period.")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }