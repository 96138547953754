var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "base", staticClass: "widget-live-metric" },
    [
      _vm.liveData
        ? _c(
            "div",
            {
              staticClass: "number-overlay",
              style: { "font-size": _vm.numberFontSize }
            },
            [
              _c("div", [_vm._v(_vm._s(_vm.metricValue))]),
              _c("div", { staticClass: "metric-suffix" }, [
                _vm._v(_vm._s(_vm.metrixSuffixText))
              ])
            ]
          )
        : _vm._e(),
      _vm.$refs.base
        ? _c(
            "b-tooltip",
            { attrs: { target: _vm.$refs.base, triggers: "hover" } },
            [
              _c(
                "ul",
                _vm._l(_vm.tooltipObjects, function(device, idx) {
                  return _c("li", { key: idx }, [
                    _vm._v(" " + _vm._s(device.device_name) + " ")
                  ])
                }),
                0
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }