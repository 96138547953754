<template>
  <div class="report-component">
    <component v-show="statsData && liveData"
               :is="currentView"
               :report="report"
               :reportParams="reportParams"
               :statsData="statsData"
               :liveData="liveData"
               :relatedIds="relatedIds"
               @required_stats="requiredStats"
               @required_device_props="requiredDeviceProps"
               ref="reportComponent"
    ></component>
  </div>
</template>
<script>

import Vue from 'vue'
import * as ErrorHelper from '@/components/helpers/ErrorHelper'
import statsDisplayTable from '@/components/stats/StatsDisplayTable'
import ReportPageBase from '@/components/stats/widgets/ReportPageParent'
import TaskProgress from '@/components/stats/TaskProgress.vue'
import DataExporter from '@/components/DataExporter.vue'

/***
 * Report Component
 *
 * Component used to render a report. This is used by both the ReportPage as the top level report and the
 * WidgetChildReport component to allow reports to be nested within each other.
 */

Vue.component('default', {
  template: '<div>Loading Report</div>'
})

export default {
  name: 'ReportComponent',
  components: { DataExporter, TaskProgress },
  props: {
    report: Object,
    reportParams: Object,
    statsData: Object,
    liveData: Object,
    relatedIds: Array
  },
  data () {
    return {
      currentView: 'default',
      testTemplate: "<div><h3>Report Loading...</h3></div>",
      customTemplate: null,
      reportTemplateIdx: 0
    }
  },
  mounted () {
    if (Object.hasOwn(this.report.data, 'template')) {
      this.currentView = this.generateComponent(this.report.data.template)
    } else {
      ErrorHelper.displayGeneralErrorToast('Report Template Not Found!', 'Error Loading Report')
    }
  },
  methods: {
    generateComponent (template) {
      this.reportTemplateIdx += 1
      let componentId = `report-page-${this._uid}-${this.reportTemplateIdx}`
      Vue.component(componentId, {
        extends: ReportPageBase,
        template: template,
        components: {
          statsDisplayTable
        },
        data () {
          return {
            number: 9999
          }
        }
      })
      return componentId
    },
    requiredStats (reqStats) {
      this.$emit('required_stats', reqStats)
    },
    requiredDeviceProps (reqDevProps) {
      this.$emit('required_device_props', reqDevProps)
    }
  },
  watch: {
    reportTemplate (newTemplate) {
      /***
       * Handle change of template, by generating a new component (component templates are static, so we need to
       * make a new one each time it changes).
       *
       * Note: This is probably inefficient as hell, but is really only used on the admin side of things to preview
       * template changes dynamically.
       */
      console.log('Report Page: Updating with Custom Template')
      this.customTemplate = newTemplate
      this.currentView = this.generateComponent(this.customTemplate)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/variables';

.report-component {
  display: flex;
  flex-direction: column;
  align-content: center;
}

.report-heading {
  color: $theme-color-primary-3;
}

.report-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: flex-start;
}

.report-test-class {
  background: $theme-color-primary-3
}

.task-popup {
  z-index: 10000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: $theme-color-background-4;
  border: 1px solid $theme-color-primary-1;
  border-radius: 10px;
  padding: 10px;
}

</style>
