<template>

</template>

<script>
export default {
  name: 'WidgetBase',
  props: {
    title: String,
    statistics: Array,
    device_props: Array
  },
  methods: {
    getRequiredStats () {
      return this.statistics
    },
    getRequiredDeviceProps () {
      return this.device_props
    }
  }
}
</script>

<style scoped>

</style>
