var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.statsData
    ? _c(
        "div",
        {
          ref: "base",
          staticClass: "widget-list",
          attrs: { id: "base" + _vm._uid }
        },
        [
          _c(
            "b-tooltip",
            { attrs: { target: "base" + _vm._uid, triggers: "hover" } },
            [_vm._v(" " + _vm._s(_vm.tooltipText) + " ")]
          ),
          _c("div", { staticClass: "background-graph" }, [
            _vm.listEntries.length
              ? _c(
                  "ol",
                  _vm._l(_vm.listEntries, function(relatedId, idx) {
                    return _c("li", { key: idx }, [_vm._v(_vm._s(relatedId))])
                  }),
                  0
                )
              : _c("div", [_vm._v(" None ")])
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }