import { render, staticRenderFns } from "./BarChartPlotly.vue?vue&type=template&id=69af65e3&scoped=true&"
import script from "./BarChartPlotly.vue?vue&type=script&lang=js&"
export * from "./BarChartPlotly.vue?vue&type=script&lang=js&"
import style0 from "./BarChartPlotly.vue?vue&type=style&index=0&id=69af65e3&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69af65e3",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/pro-tekt/protekt_webapp/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('69af65e3')) {
      api.createRecord('69af65e3', component.options)
    } else {
      api.reload('69af65e3', component.options)
    }
    module.hot.accept("./BarChartPlotly.vue?vue&type=template&id=69af65e3&scoped=true&", function () {
      api.rerender('69af65e3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/stats/charts/BarChartPlotly.vue"
export default component.exports